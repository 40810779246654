import Vue from 'vue';
import { compose, equals, path, forEach, nth, assoc, map } from 'ramda';
import { callMutation, commitMutations, getState, commitMutation } from '@/helpers/vuex';
import { setProp } from '@/helpers/object';

/** 選取 [收件人] */
export const makeOnSelect = (module, mutation) => (index, item) => {
  callMutation(module, mutation, index);
  commitMutations(module, { selectedItem: item, dataDismiss: 'modal' });
};

/** 若已經選取 [統編收件人]，才可以關閉視窗 */
export const makeIsWindowClosable = (module) => compose(equals('modal'), getState(module, 'dataDismiss'));

/** 從 [未選取] 變成 [選取] */
export const makeToggleTitle = (stateName) => (state, payload) =>
  compose(setProp('caption', '選取'), nth(payload), forEach(setProp('caption', '未選取')), path([stateName]))(state);

/** 新增 [caption] property 顯示用 */
export const addProperty = (prop, apiNode) => compose(map(assoc(prop, '未選取')), path(apiNode));

const createModal = (componentName) => {
  const element = document.querySelector(`#${componentName}`);
  if (element === null) {
    // eslint-disable-next-line no-console
    console.error(`[modal] 無法取得 DOM 元件`);
    return null;
  }
  return new Modal(element);
};

export const openModal = (componentName) => {
  commitMutation('Modal', 'modal', {
    name: `isShow${componentName}`,
    value: true,
  });
  Vue.nextTick(() => {
    const modal = createModal(componentName);
    // edge case: user在modal開啟的狀態下直接按上一頁
    if (document.querySelector('.modal-backdrop') === null) {
      const div = document.createElement('div');
      div.classList.add('modal-backdrop', 'fade', 'in');
      document.querySelector('body').appendChild(div);
    }
    if (modal !== null) {
      modal.show();
    }
  });
};

export const closeModal = (componentName) => {
  const modal = createModal(componentName);
  if (modal !== null) {
    modal.hide();
    commitMutation('Modal', 'modal', {
      name: `isShow${componentName}`,
      value: false,
    });
  }
};
