import { defaultUserData } from '@/store/modules/login/default-user-data';
import { makeMutation } from '@/helpers/vuex';

const state = {
  user: '',
  isLogin: false,
  /** 呼叫過登入 api? (getCartCount) */
  call: null,
  /** user 來源 for Mobile用 */
  isFromApp: 0,
};

const mutations = {
  userData(state, userData) {
    state.user = userData.memberName;
    state.level = parseInt(userData.memberLevel, 10) || 0;
    return state.user;
  },
  login(state) {
    state.isLogin = true;
    state.call = true;
    return state.isLogin;
  },
  logout(state) {
    state.user = defaultUserData;
    state.isLogin = false;
    state.call = false;
    return state.isLogin;
  },
  isCall(state, userData = false) {
    state.call = userData;
    return state.call;
  },
  setUser: makeMutation('user'),
  setIsLogin: makeMutation('isLogin'),
  setCall: makeMutation('call'),
  setIsFromApp: makeMutation('isFromApp'),
};

const actions = {
  userData(context, userData) {
    context.commit('userData', userData);
  },
  login(context) {
    context.commit('login');
  },
  logout(context) {
    context.commit('logout');
  },
  isCall(context, userData = false) {
    context.commit('isCall', userData);
  },
};

const getters = {
  /**
   * 取得 user name
   * @param {state} state state
   */
  getUser(state) {
    return state.user || '';
  },
  /**
   * 登入？
   * @param {state} state state
   */
  isLogin(state) {
    return state.isLogin || false;
  },
  /**
   * 呼叫過登入 api ？
   * @param {state} state state
   */
  isCall(state) {
    return state.call;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
