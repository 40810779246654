import { makeMutation, commitMutation, dispatchAction } from '@/helpers/vuex';
import { isMobile } from '@/helpers/is-from-app';
import { router } from '@/pages/default/router';
import { getBanner } from '@/api/get-banner';
import { createPromotionItem, sendGA4sEvent } from '@/helpers/tracking/ga';

const state = {
  order: {
    /** 訂單編號 */
    id: '',
    /**  銀行名稱 */
    bank: '',
    /**  訂單金額 */
    amount: '0',
    /**  分期期數 */
    period: '0',
    /**  每期金額 */
    perAmount: '0',
    /**  轉帳帳戶 (ATM用) */
    account: '',
    /**  截止日/失效日 (ATM/CCR) */
    expireDate: '',
    /**  使用點數 (紅利折抵) */
    usedPoint: '0',
    /**  折抵金額 (紅利折抵) */
    discount: '0',
    /**  剩餘紅利 (紅利折抵) , 無剩餘紅利資料則維持-1 */
    unusedPoint: '',
  },
  flag: {
    /**  是否顯示2R 加好友 */
    isShowAddFriend: false,
    /** 付款方式 (component Name) */
    paymentType: 'fail',
  },
  /** @var {null|object} info 購物車列表 */
  cartInfo: null,
  /** @param {number} total 數量 > 0 且非預購車的總數量 */
  cartTotal: 0,
  /** user 來源 for Mobile用 */
  isFromApp: 0,
  banners: [],
};

const setAddFriend = (state, isShow) => (state.flag.isShowAddFriend = isShow);
const setPaymentType = (state, type) => (state.flag.paymentType = type);
const setIsFromApp = (state, flag) => (state.isFromApp = flag);
const setOrderId = (state, orderId) => (state.order.id = orderId);
const setBank = (state, bank) => (state.order.bank = bank);
const setAmount = (state, amount) => (state.order.amount = amount.toLocaleString('en-US'));
const setPeriod = (state, period) => (state.order.period = period);
const setAccount = (state, account) => (state.order.account = account);
const setExpireDate = (state, date) => (state.order.expireDate = date);
const setUsePoint = (state, point) => (state.order.usedPoint = point.toLocaleString('en-US'));
const setDiscount = (state, amount) => (state.order.discount = amount.toLocaleString('en-US'));
const setUnusedPoint = (state, point) => (state.order.unusedPoint = point.toLocaleString('en-US'));
const setPerAmount = (state, amount) => (state.order.perAmount = amount.toLocaleString('en-US'));
const setCartList = makeMutation('cartList');
const setCartInfo = makeMutation('cartInfo');
const setCartTotal = makeMutation('cartTotal');
const cleanCart = (state) => {
  state.cartList.info = null;
  state.cartList.total = 0;
};
// 用於刪除或收藏商品時重新計算車裡商品數量
const changeCartCount = (state, cartData) => {
  // 如果該車已無商品
  if (cartData.count === 0) {
    if (isMobile()) {
      router.push('/m/cart/choice');
      return;
    }
    const updateCartInfo = state.cartInfo.filter((item) => item.cartType !== cartData.cartType);
    const updateCartTotal = updateCartInfo.reduce((sum, x) => sum + x.count, 0);
    // 如果已無任何購物車時，show empty view
    if (updateCartTotal === 0) {
      commitMutation('Cart', 'cartTotal', 0);
      state.cartInfo = updateCartInfo;
      return;
    }
    state.cartInfo = updateCartInfo;
    state.cartTotal = updateCartTotal;
    commitMutation('CartDetail', 'cartType', state.cartInfo[0].cartType);
    router.push(
      {
        path: '/cart/main',
        query: { type: state.cartInfo[0].cartType },
      },
      () => {},
      () => {}
    );
    return;
  }
  if (isMobile()) {
    commitMutation('CartDetail', 'cartDetail', cartData.updateList);
    // 呼叫重新排列手機版 cartMain List
    dispatchAction('CartDetail', 'getMobileCartMain');
    return;
  }
  const findCart = state.cartInfo.find((item) => item.cartType === cartData.cartType);
  findCart.count = cartData.count;
  state.cartTotal = state.cartInfo.reduce((sum, x) => sum + x.count, 0);
  commitMutation('CartDetail', 'cartDetail', cartData.updateList);
};

const reconstructBanners = (data) =>
  data.map((item) => {
    const ads = item.component.reduce(
      (acc, cur) => {
        const type = cur.componentType;
        if (type === 'image') {
          const found = cur.adsLists.find((item) => item.banner);
          if (found) {
            acc.img = found.banner;
          }
        }
        if (type === 'link') {
          const found = cur.adsLists.find((item) => item.url && item.trackingId);
          if (found) {
            const { url, action, trackingId, anchor } = found;
            acc.url = url;
            acc.action = action;
            acc.anchor = anchor;
            acc.items.push({ item_name: type, item_id: trackingId });
          }
        }
        if (type === 'content') {
          acc.content = cur.adsLists.map((item) => item.content);
        }
        return acc;
      },
      {
        img: '',
        url: '',
        action: '',
        anchor: '',
        content: [],
        items: [],
      }
    );
    return {
      ...ads,
      alt: item.adName,
      promotion_id: item.trackingId,
      creative_slot: 'OC',
    };
  });

const mutations = {
  setPaymentType,
  setAddFriend,
  setIsFromApp,
  setOrderId,
  setBank,
  setAmount,
  setPeriod,
  setAccount,
  setExpireDate,
  setUsePoint,
  setDiscount,
  setUnusedPoint,
  setPerAmount,
  setCartList,
  setCartInfo,
  setCartTotal,
  cleanCart,
  changeCartCount,
  SET_BANNERS(state, data) {
    state.banners = reconstructBanners(data);
  },
};

const actions = {
  setCartList(context, userData) {
    context.commit('setCartList', userData);
  },
  cleanCart(context) {
    context.commit('cleanCart');
  },
  fetchBanners: ({ commit, state }) => {
    getBanner({ bannerCode: 'OC' }).then((res) => {
      commit('SET_BANNERS', res.data.data.ad);
      state.banners.forEach((item) => {
        const parameterForGA = createPromotionItem(item);
        sendGA4sEvent('view_promotion', parameterForGA);
      });
    });
  },
};

const getters = {
  getInfoTotal(state) {
    // for 小車狀態 || isEmpty
    return state.cartTotal === 0 ? 0 : state.cartInfo.reduce((sum, x) => sum + x.count, 0);
  },
  getCartInfo(state) {
    return state.cartInfo || [];
  },
  getIsFromApp(state) {
    return state.isFromApp;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
