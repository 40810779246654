import { path } from 'ramda';
import { makeMutation } from '@/helpers/vuex';
import fetchCityTownTaiwan from '@/api/step1/city-town-taiwan';
import { defaultCityTownTaiwan } from '@/components/desktop/step1/common/city-town/city-town.default';

const cartMainUrl = `${process.env.VUE_APP_CART}/cart/main`;

/** 儲存 [縣市鄉鎮] */
const saveAllCityTownTaiwan =
  (commit) =>
  ({ data: { data } }) => {
    if (data === undefined) {
      alert('無法取得縣市地區資訊');
      return window.location.replace(cartMainUrl);
    }

    return commit('setAllCityTownTaiwan', data);
  };

/** 先 [取得縣市鄉鎮] API，再 [取得信用卡持卡人 /getInfo] API */
const getAllCityTownTaiwan = ({ commit }) =>
  fetchCityTownTaiwan()
    .then(saveAllCityTownTaiwan(commit))
    .catch((error) => {
      console.log(error);
      alert('無法取得縣市地區資訊');
      return window.location.replace(cartMainUrl);
    });

/** 只[取得縣市鄉鎮] API 不取[取得信用卡持卡人 /getInfo] API for R 身份 */
const getAllCityTownTaiwanNoGetInfo = ({ commit }) =>
  fetchCityTownTaiwan()
    .then(saveAllCityTownTaiwan(commit))
    .catch((error) => {
      console.log(error);
      alert('無法取得縣市地區資訊');
      return window.location.replace(cartMainUrl);
    });

export default {
  namespaced: true,
  state: {
    /** 縣市鄉鎮 */
    allCityTownTaiwan: defaultCityTownTaiwan,
    cityId: '',
    townId: '',
  },
  getters: {
    /** 台灣所有鄉鎮市 */
    cities: path(['allCityTownTaiwan', 'cities']),
  },
  mutations: {
    /** 修改 [縣市鄉鎮] */
    setAllCityTownTaiwan: makeMutation('allCityTownTaiwan'),
    setCityId: makeMutation('cityId'),
    setTownId: makeMutation('townId'),
  },
  actions: {
    getAllCityTownTaiwan,
    getAllCityTownTaiwanNoGetInfo,
  },
};
