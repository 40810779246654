import { makeMutation } from '@/helpers/vuex';
import { getCityIdByName, getTownIdByName } from '@/helpers/city-town';

/** 修改 [付款人姓名] */
const setName = makeMutation('name');

/** 修改 [付款人手機] */
const setMobilePhone = makeMutation('mobilePhone');

/** 修改 [付款人電子郵件] */
const setEmail = makeMutation('email');

/** 修改 [[付款人市話]] */
const setLocalPhoneArea = makeMutation('localPhoneArea');

/** 修改 [付款人市話] */
const setLocalPhone = makeMutation('localPhone');

/** 修改 [付款人市話分機] */
const setLocalPhoneExt = makeMutation('localPhoneExt');

/** 修改 [郵遞區號] */
const setAddressZip = makeMutation('addressZip');

/** 修改 [付款人居住城市 ID] */
const setAddressCityId = makeMutation('addressCityId');

/** 修改 [付款人居住城市] */
const setAddressCity = makeMutation('addressCity');

/** 修改 [付款人居住地區 ID] */
const setAddressTownId = makeMutation('addressTownId');

/** 修改 [付款人居住地區] */
const setAddressTown = makeMutation('addressTown');

/** 修改 [付款人居住地址] */
const setAddress = makeMutation('address');

/** 根據 [付款人居住城市] 更新 [付款人居住城市 ID] */
const updateAddressCityId = (state) => {
  state.addressCityId = getCityIdByName({ cityName: state.addressCity });
};

/** 根據 [付款人居住地區] 更新 [付款人居住地區 ID] */
const updateAddressTownId = (state) => {
  state.addressTownId = getTownIdByName({
    cityName: state.addressCity,
    townName: state.addressTown,
  });
};

export default {
  namespaced: true,
  state: {
    /** 由 [設定購物車結帳] API 取得 [付款人姓名] */
    name: '',
    /** 由 [設定購物車結帳] API 取得 [付款人手機] */
    mobilePhone: '',
    /** 由 [設定購物車結帳] API 取得 [付款人電子郵件] */
    email: '',
    /** 由 [設定購物車結帳] API 取得 [付款人市話區碼] */
    localPhoneArea: '',
    /** 由 [設定購物車結帳] API 取得 [付款人市話] */
    localPhone: '',
    /** 由 [設定購物車結帳] API 取得 [付款人市話分機] */
    localPhoneExt: '',
    /** [郵遞區號] */
    addressZip: '',
    /** 付款人居住城市 ID */
    addressCityId: '',
    /** 由 [設定購物車結帳] API 取得 [付款人居住城市] */
    addressCity: '',
    /** 付款人居住地區 ID */
    addressTownId: '',
    /** 由 [設定購物車結帳] API 取得 [付款人居住地區] */
    addressTown: '',
    /** 由 [設定購物車結帳] API 取得 [付款人居住地址] */
    address: '',
    cityId: '',
    townId: '',
  },
  mutations: {
    setName,
    setMobilePhone,
    setEmail,
    setLocalPhoneArea,
    setLocalPhone,
    setLocalPhoneExt,
    setAddressZip,
    setAddressCityId,
    setAddressCity,
    setAddressTownId,
    setAddressTown,
    setAddress,
    updateAddressCityId,
    updateAddressTownId,
    setCityId: makeMutation('cityId'),
    setTownId: makeMutation('townId'),
  },
};
