<template>
  <transition name="scroll-to-top">
    <a class="btn-gotop" v-if="isNotTop" v-on:click="clickToScroll">
      <i class="icon icon-arrow-up"></i>
      <span>TOP</span>
    </a>
  </transition>
</template>

<script>
import smoothscroll from 'smoothscroll-polyfill';

// kick off the polyfill!
smoothscroll.polyfill();

export default {
  data() {
    return {
      isNotTop: false,
    };
  },
  methods: {
    showTopBtn() {
      this.isNotTop = window.scrollY > 150;
    },
    clickToScroll() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    },
  },
  created() {
    window.addEventListener('scroll', this.showTopBtn);
  },
  destroyed() {
    window.removeEventListener('scroll', this.showTopBtn);
  },
};
</script>

<style scoped>
.btn-gotop {
  display: block;
  opacity: 1;
}
.scroll-to-top-enter-active,
.scroll-to-top-leave-active {
  transition: opacity 1s;
}
.scroll-to-top-enter-to {
  opacity: 1;
}
.scroll-to-top-enter,
.scroll-to-top-leave-to {
  opacity: 0;
}
</style>
