/**
 * Loading status
 */

import { makeMutation } from '@/helpers/vuex';

const state = {
  isLoading: false,
  updateLoading: false,
};

const updateLoadingStatus = (state, payload) => {
  state.isLoading = payload.isLoading || false;
};

const mutations = {
  updateLoadingStatus,
  setUpdateLoading: makeMutation('isLoading'),
};

const actions = {
  updateLoading(context, payload) {
    context.commit('updateLoadingStatus', payload);
  },
};

const getters = {
  getLoadingStatus(state) {
    return state.isLoading;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
