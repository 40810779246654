import { curry } from 'ramda';

/** 對字串的第一個字大寫 */
export const capitalize = (word) => word[0].toUpperCase() + word.slice(1);

/** 字串左側補 0 */
export const padLeftZero = curry((length, str) => {
  if (String(str).length >= length) return String(str);
  return padLeftZero(length, `0${str}`);
});

export const substr = curry((start, length, str) => str.substr(start, length));

/** 網址取得參數 json */
export const getURLParameters = (url) => {
  if (typeof url === 'undefined') {
    url = window.location.href;
  }
  const res = {};
  const splittedURL = url.split('?');
  if (splittedURL !== undefined && splittedURL.length > 1) {
    const params = splittedURL[1].split('&');
    for (let i = 0; i < params.length; i++) {
      // eslint-disable-next-line prefer-destructuring
      res[params[i].split('=')[0]] = params[i].split('=')[1];
    }
  }
  return res;
};
