import { commitMutation, renderState } from '@/helpers/vuex';
import mobileDevice from '@/helpers/device';

const { protocol } = window.location;
const { hostname } = window.location;

// 抓出目前 domain for 轉址
export const redirectHostname = () => `${protocol}//${hostname}`;
export const isMobile = () => (mobileDevice.mobile() || mobileDevice.tablet()) !== null;
export const cocoBack = process.env.VUE_APP_COCO_BACK;
export const online = () => process.env.VUE_APP_ONLINE;
/** 判斷裝置來源 0: 非APP | 1: from 神腦 APP | 2: from much much */
export const getGoBackWhere = () => renderState('Cart', 'isFromApp');
/** 手機版_從 user 來源 (isFromApp) 判斷回上一頁的按鈕連結
 * 0: from PC or mWeb | 1: from 神腦 APP | 2: from much much */
export const setUrl = () => {
  switch (getGoBackWhere()) {
    case 1:
      return `${process.env.VUE_APP_ONLINE}/goBackApp`;
    case 2:
      return `${cocoBack}/goBackApp`;
    default:
      return `${process.env.VUE_APP_ONLINE}`;
  }
};
/** check 是否 from much much OR mWeb
 * @param{Boolean} isLogin
 * @return{Number} isFromApp
 * */
// TODO: 確認拔掉isFromApp === 3 後的邏輯是否正確
export const isFromWhere = () => {
  commitMutation('Cart', 'isFromApp', getGoBackWhere());
};

export const isNotFromMuchMuch = () => getGoBackWhere() !== 2;
export const isFromMuchMuch = () => getGoBackWhere() === 2;

// 如果來源是much much 即導去「銷售訂單」頁
// online (PC || Mobile || APP) => /order/list || /m/order/list || /m/order/list
// much much (PC || Mobile || APP) => cartChoice || cartChoice || /order
export const getOrderListPath = () => {
  const redirectPathFromDevice = isMobile() ? `${redirectHostname()}/m/order/list` : `${redirectHostname()}/order/list`;
  return isFromMuchMuch() ? `${process.env.VUE_APP_COCO_BACK}/order` : redirectPathFromDevice;
};

export const isLoginUser = () => renderState('CartDetail', 'isLogin') === true;
