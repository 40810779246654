<template>
  <div id="app">
    <CartMain v-if="!isMobile" :isMobile="isMobile"></CartMain>

    <router-view v-else :isMobile="isMobile">
      <div class="container"></div>
    </router-view>

    <loading v-if="isLoading"></loading>
    <link rel="stylesheet" :href="checkStylePath" />
    <link rel="stylesheet" href="/stylesheets/mobile-modal.css" v-if="isMobile" />
  </div>
</template>

<script>
import CartMain from '@/views/cart-main.vue';
import Loading from '@/components/Utils/loading.vue';
import { isMobile } from '@/helpers/is-from-app';

const components = {
  CartMain,
  Loading,
};

const computed = {
  /** 取得 style 路徑 */
  checkStylePath() {
    return this.isMobile ? '/stylesheets/mobile.css' : '/stylesheets/main-cart.css';
  },
  /** loading 開關 */
  isLoading() {
    return this.$store.getters['Loading/getLoadingStatus'];
  },
  isMobile,
};

const methods = {
  setMobile() {
    const body = document.querySelector('body');
    body.classList[this.isMobile ? 'add' : 'remove']('mobile');
  },
  setFaviconAndTitle() {
    const headFavicon = document.querySelector("link[rel~='icon']");
    const appleTouchIcon = document.querySelector("link[rel~='apple-touch-icon']");
    headFavicon.href = `${process.env.VUE_APP_CART}/images/favicon/favicon.ico`;
    appleTouchIcon.href = `${process.env.VUE_APP_CART}/images/favicon/app-touch-icon.png`;
  },
};

export default {
  name: 'home',
  components,
  computed,
  methods,
  mounted() {
    this.setMobile();
    this.setFaviconAndTitle();
  },
  destroyed() {
    this.setMobile();
  },
};
</script>
