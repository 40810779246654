import { makeMutation } from '@/helpers/vuex';

const defaultState = {
  /** 付款方式 */
  paymentMethod: 'creditcard-onetime',
  /** 電子發票-個人 */
  electronicInvoice: '',
  /** 配送方式 */
  deliveryMethod: '',
  /** 發票開立方式，預設為電子發票-個人 */
  invoiceMethod: 'electronic-invoice',
  cartMain: 'default-view',
};

export default {
  namespaced: true,
  state: Object.assign({}, defaultState),
  mutations: {
    setPaymentMethod: makeMutation('paymentMethod'),
    setElectronicInvoice: makeMutation('electronicInvoice'),
    setDeliveryMethod: makeMutation('deliveryMethod'),
    setInvoiceMethod: makeMutation('invoiceMethod'),
    setCartMain: makeMutation('cartMain'),
    /** 重置 store */
    RESET_STATE(state) {
      Object.keys(state).forEach((prop) => {
        state[prop] = defaultState[prop];
      });
    },
  },
};
