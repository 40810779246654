/** XSRF Settings */
export const xsrfAlive = 15; // 15 min
export const xsrfKeyName = 'xsrfToken';
const tokenAllowWords = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890';
const tokenLength = 20;

/** Methods */
export const createToken = () => {
  const allowWords = tokenAllowWords.split('');
  let str = '';
  for (let i = 0; i < tokenLength; i++) {
    str += allowWords[Math.floor(Math.random() * allowWords.length)];
  }
  return str;
};
