import { makeMutation } from '@/helpers/vuex';
import fetchAddressee from '@/api/step1/addressee';
import { addProperty, makeToggleTitle } from '@/helpers/modal';
import { getZipCodeByCityTownName } from '@/helpers/city-town';

/** 從 [未選取] 變成 [選取] */
const toggleTitle = makeToggleTitle('addressees');

/** 儲存 [收件人記事本] */
const saveAddressees = (commit) => (data) => commit('setAddressees', data);

/** [收件人記事本] API */
const getAddressees = ({ commit }) =>
  fetchAddressee()
    .then((res) => {
      res.data.data.list.map((item) => {
        const { cityName, townName } = item;
        item.cityId = item.cityId.toString();
        item.townId = item.townId.toString();
        item.zipcode = getZipCodeByCityTownName({ cityName, townName });
        return item;
      });
      return res;
    })
    .then(addProperty('caption', ['data', 'data', 'list']))
    .then(saveAddressees(commit));

export default {
  namespaced: true,
  state: {
    /** 從 API 下載的 [收件人記事本] */
    addressees: [],
    /** 所選擇 [收件人] */
    selectedItem: [],
    /** 關閉 [收件人記事本] */
    dataDismiss: '',
    /** 是否有打過[收件人記事本] API */
    hasLoadAddressInfo: false,
  },
  mutations: {
    setAddressees: makeMutation('addressees'),
    setSelectedItem: makeMutation('selectedItem'),
    setDataDismiss: makeMutation('dataDismiss'),
    toggleTitle,
    setHasLoadAddressInfo: makeMutation('hasLoadAddressInfo'),
  },
  actions: {
    getAddressees,
  },
};
