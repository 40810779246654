const state = {
  isShowHamipointModal: false,
  isShowEdenredModal: false,
  isShowLoginModal: false,
  isShowGoLoginModal: false,
  isShowZingalaModal: false,
  isShowZingalaNoticeModal: false,
  isShowCcrModal: false,
  isShowRegisterModal: false,
  isShowCreditcardModal: false,
  isShowBankModal: false,
  isShowVtpModal: false,
  isShowCouponModal: false,
};
const setModal = (state, payload) => {
  const { name, value } = payload;
  state[name] = value;
};

const mutations = {
  setModal,
};

export default {
  namespaced: true,
  state,
  mutations,
};
