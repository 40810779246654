import { dispatchAction, commitMutation, commitMutations, getGetter, renderState } from '@/helpers/vuex';
import { isMobile } from '@/helpers/is-from-app';
import { removeEdenred } from '@/api/cart-main';
import { setEdenredBlockContent } from '@/store/modules/step1/cart-detail';

export const getEdenredGroup = getGetter('CartDetail', 'getterEdenredGroup');

export const formatEdenredSerialText = () => {
  const filterEdenredSerial = getEdenredGroup().filter((item) => item.serial);
  const insertSeparatorComma = [];
  filterEdenredSerial.forEach((item) => {
    insertSeparatorComma.push(item.serial);
  });
  return insertSeparatorComma.join('、 ');
};

export const edenredBalanceTotal = () => {
  const filterHaveBalance = getEdenredGroup().filter((item) => item.balance);
  return filterHaveBalance.reduce((sum, x) => sum + x.balance, 0);
};

export const isUserNotOwnedEdenredForShowText = () => {
  const checkEdenred = getEdenredGroup();
  return checkEdenred.length === 0;
};

export const isVerifySerialNumber = (userKeySerial) => {
  const verifyTwelveNumber = /^[0-9]{12}$/;
  return verifyTwelveNumber.test(userKeySerial);
};

export const showErrorTxtAtNotVerified = (isVerifiedSerialNumber) => {
  const errTxt =
    isVerifiedSerialNumber === false
      ? 'Edenred 序號需輸入「數字」共12碼，請重新輸入'
      : '此序號已驗證過，請輸入其他未驗證的序號';
  commitMutations('CartDetail', {
    edenredIsError: true,
    edenredErrorTxt: errTxt,
  });
};

export const fetchEdenredByVerified = (userKeySerial) => {
  commitMutation('CartDetail', 'edenredIsError', false);
  return dispatchAction('CartDetail', 'fetchEdenred', userKeySerial);
};

export const getEdenredTotalCount = () => getEdenredGroup().length;

export const isShowAvailableBalance = () => {
  const isHasBalance = renderState('CartDetail', 'isFetchEdenredHasBalance');
  const isEdenredError = renderState('CartDetail', 'edenredIsError');
  return isEdenredError === false && isHasBalance !== false;
};

const redirectToLogin = () => {
  const redirectPathByDevice = isMobile() ? '/m/Member/Login?redirect=' : '/login?redirect=';
  const redirectPathPageByDevice = isMobile() ? '/m/cart/choice' : '/cart/main';
  window.location.href = `${process.env.VUE_APP_ONLINE}${redirectPathByDevice}${encodeURIComponent(
    process.env.VUE_APP_CART
  )}${redirectPathPageByDevice}`;
};

const saveDelEdenredResponse = (data) => {
  commitMutation('CartDetail', 'edenredGroup', data.edenred || []);
  setEdenredBlockContent();
};

const checkDelEdenredResponseCodeState = (data) => {
  const notLoginCode = 2;
  if (data.code === notLoginCode) {
    alert('請先登入神腦生活會員。');
    redirectToLogin();
  }
  const removeFailCode = 0;
  if (data.code === removeFailCode) {
    alert('移除失敗');
    saveDelEdenredResponse(data.data);
  }
};

export const checkSerialNumberAndFetchEdenred = (userKeySerial) => {
  const isVerifiedSerialNumber = isVerifySerialNumber(userKeySerial);
  const isCheckDuplicatedSN = getEdenredGroup().find((item) => item.serial === `SED${userKeySerial}`);
  if (isVerifiedSerialNumber === false || isCheckDuplicatedSN) {
    showErrorTxtAtNotVerified(isVerifiedSerialNumber);
    return;
  }
  fetchEdenredByVerified(userKeySerial);
};

export const deleteThisEdenred = (thisItem) => {
  const formData = new FormData();
  formData.append('serial', thisItem.serial);
  const response = (res) => {
    const { data } = res;
    checkDelEdenredResponseCodeState(data);
    const fetchSuccess = 1;
    if (data.code === fetchSuccess) {
      saveDelEdenredResponse(data.data);
    }
  };
  removeEdenred(formData).then(response);
};
