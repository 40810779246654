<template>
  <div class="nav-account">
    <i class="icon" :class="userStatus"></i>
    <ul class="nav">
      <li v-if="userIsLogin">
        <a @click="redirectUrl('Member')">
          <span :title="userName" class="username">
            {{ maskName(userName) }}
          </span>
        </a>
        ︱
        <a @click="redirectUrl('Logout')">登出</a>
        <div class="popover-hover">
          <div class="popover-nav">
            <a @click="redirectUrl('Member')">
              會員中心
              <i class="icon icon-arrow-down"></i>
            </a>
          </div>
          <div class="popover bottom">
            <div class="popover-arrow"></div>
            <div class="nav nav-list">
              <a @click="redirectUrl('Order/list')" title="訂單查詢"> 訂單查詢 </a>
              <a @click="redirectUrl('eTicket')" title="電子票券"> 電子票券 </a>
              <a @click="redirectUrl('Coupon')" title="優惠券"> 優惠券 </a>
              <a @click="redirectUrl('Point')" title="神腦幣"> 神腦幣 </a>
              <a @click="redirectUrl('Member/mailInbox')" title="個人訊息">
                個人訊息
                <b v-if="messageCount > 0" class="notice fn-mini spacing left mini"> ({{ messageCount }}) </b>
              </a>
            </div>
          </div>
        </div>
      </li>

      <li v-else>
        <a @click="loginOrRegister('login')">登入</a>
        <span class="spacing sm">︱</span>
        <a @click="loginOrRegister('register')">註冊</a>
        <div class="popover-hover">
          <div class="popover-nav">
            <a @click="redirectUrl('Member')">
              會員中心
              <i class="icon icon-arrow-down"></i>
            </a>
          </div>
          <div class="popover bottom">
            <div class="popover-arrow"></div>
            <div class="nav nav-list">
              <a @click="redirectUrl('Order/list')" title="訂單查詢"> 訂單查詢 </a>
              <a @click="redirectUrl('eTicket')" title="電子票券"> 電子票券 </a>
              <a @click="redirectUrl('Coupon')" title="優惠券"> 優惠券 </a>
              <a @click="redirectUrl('Point')" title="神腦幣"> 神腦幣 </a>
              <a @click="redirectUrl('Member/mailInbox')" title="個人訊息"> 個人訊息 </a>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { getMessageCount } from '@/api/header-message-count';
import { createRegistrationItem, sendGA4sEvent } from '@/helpers/tracking/ga';
import { maskName } from '@/helpers/mask';

const baseUrl = process.env.VUE_APP_ONLINE;
const cartUrl = process.env.VUE_APP_CART;
const noLoginBaseUrl = `${baseUrl}/login?redirect=${encodeURIComponent(baseUrl)}/`;

const methods = {
  getMessage() {
    // 取得未讀筆數
    getMessageCount()
      .then((res) => {
        // 呼叫失敗不影響其他操作
        if (res.data.code !== 1) {
          return;
        }
        // 個人訊息數
        this.messageCount = res.data.data.messageCount || 0;
      })
      .catch(() => (this.messageCount = 0));
  },
  redirectUrl(link) {
    if (!this.userIsLogin) {
      alert('請先登入會員！');
    }
    return (window.location.href = `${noLoginBaseUrl}${encodeURIComponent(link)}`);
  },
  redirectCartMainUrl(link) {
    window.location.href = `${baseUrl}/${link}?redirect=${encodeURIComponent(cartUrl)}${encodeURIComponent(
      '/cart/main'
    )}`;
  },
  loginOrRegister(link) {
    if (link === 'register') {
      setTimeout(this.redirectCartMainUrl(link), 10000);
      try {
        const initialData = createRegistrationItem('開始註冊', 'header');
        const resultData = Object.assign({}, initialData, {
          event_callback: () => {
            this.redirectCartMainUrl(link);
          },
        });
        sendGA4sEvent('sign_up_start', resultData);
      } catch {
        this.redirectCartMainUrl(link);
      }
    } else {
      this.redirectCartMainUrl(link);
    }
  },
  maskName,
};

export default {
  name: 'user',
  data() {
    return {
      defaultName: '會員您好',
      messageCount: 0,
    };
  },
  methods,
  computed: {
    userIsLogin() {
      const isLogin = this.$store.getters['Login/isLogin'];
      // 已登入 - 取個人訊息未讀筆數
      if (isLogin) {
        this.getMessage();
      }
      return isLogin;
    },
    userName() {
      return this.userIsLogin ? this.$store.getters['Login/getUser'] : this.defaultName;
    },
    userStatus() {
      return this.userIsLogin ? 'icon-user-general' : 'icon-user-outline';
    },
  },
};
</script>
