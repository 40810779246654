import { makeMutation } from '@/helpers/vuex';
import { createPromotionItem, sendGA4sEvent } from '@/helpers/tracking/ga';
import { getFinishedAd } from '@/api/get-finished-ad';

const defaultState = {
  isShowInsurance: false,
  bindChtAccount: '',
  Order: {},
  plusPayQrCode: '',
  plusPayPollingUrl: '',
  execScript: '',
  paymentReviewDate: '',
  banners: [],
};

const reconstructBanners = ({ data, bannerCode }) =>
  data.map((item) => {
    const ads = item.component.reduce(
      (acc, cur) => {
        const type = cur.componentType;
        const found = cur.adsLists.find((item) => item.banner);
        if (found) {
          const { banner, url, action, trackingId, anchor } = found;
          acc.img = banner;
          acc.url = url;
          acc.action = action;
          acc.anchor = anchor;
          acc.items.push({ item_id: trackingId, item_name: type });
        }
        return acc;
      },
      {
        img: '',
        url: '',
        action: '',
        anchor: '',
        items: [],
      }
    );
    return {
      ...ads,
      alt: item.adName,
      promotion_id: item.trackingId,
      creative_slot: bannerCode,
    };
  });

export default {
  namespaced: true,
  state: Object.assign({}, defaultState),
  mutations: {
    setOrder: makeMutation('Order'),
    setIsShowInsurance: makeMutation('isShowInsurance'),
    setBindChtAccount: makeMutation('bindChtAccount'),
    setPlusPayQrCode: makeMutation('plusPayQrCode'),
    setPlusPayPollingUrl: makeMutation('plusPayPollingUrl'),
    setExecScript: makeMutation('execScript'),
    setPaymentReviewDate: makeMutation('paymentReviewDate'),
    /** 重置 store */
    RESET_STATE(state) {
      Object.keys(state).forEach((prop) => {
        state[prop] = defaultState[prop];
      });
    },
    SET_BANNERS(state, data) {
      state.banners = reconstructBanners(data);
    },
  },
  actions: {
    fetchBanners: ({ commit, state }, payload) => {
      getFinishedAd({ bannerCode: payload }).then((res) => {
        commit('SET_BANNERS', { data: res.data.data.ad, bannerCode: payload });
        state.banners.forEach((item) => {
          const parameterForGA = createPromotionItem(item);
          sendGA4sEvent('view_promotion', parameterForGA);
        });
      });
    },
  },
};
