export const paymentEnum = {
  /** 信用卡紅利 */
  creditCardBonus: 99,
  /** 信用卡一次 */
  creditCardOnetime: 100,
  /** 信用卡分期 */
  creditCardInstallment: 199,
  /** ATM 轉帳 */
  ATMTransfer: 200,
  /** 門市繳費 */
  storePayment: 300,
  /** LINE 付款 */
  linePayment: 400,
  /** 街口支付 */
  jkosPayment: 401,
  /** PI支付 */
  PiPayment: 402,
  /** TW Pay 支付 */
  TWPayment: 403,
  /** 全盈+PAY 支付 */
  PlusPayPayment: 404,
  /** 悠遊付 */
  EasyPayPayment: 405,
  /** Apple Pay 支付 */
  ApplePayPayment: 500,
  /** 銀角零卡分期 */
  ZingalaPayment: 600,
  /** 手機版的行動支付 Group */
  MobilePayment: 888,
};

export const arrangeMobilePaymentEnum = {
  500: 1,
  403: 2,
  402: 3,
  404: 4,
  401: 5,
  405: 6,
  400: 7,
};

/** [付款方式] 為可顯示狀態 */
/**
 * 0 : Not Visible
 * 1 : Enable
 * 2 : Disable
 */
export const paymentStatusEnum = {
  notVisible: 0,
  enable: 1,
  disable: 2,
};
/** 用來判斷可否打分期 API */
export const isinstallmentEnum = {
  notIstallable: 0,
  installable: 1,
};
/** 用來判斷可否打零卡分期 API */
export const isFetchZingalaEnum = {
  notFetch: 0,
  hasFetch: 1,
};
