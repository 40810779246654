<template>
  <div class="nav-cart">
    <i class="icon icon-cart-outline"></i>
    <ul class="nav">
      <li>
        商品
        <a id="cart-items-total" :href="getCartMainUrl" class="badge badge-danger circle">
          {{ getTotal }}
        </a>
        項
      </li>
      <li>
        <div class="popover-hover">
          <div class="popover-nav">
            購物車
            <i class="icon icon-arrow-down"></i>
          </div>
          <div id="cart_nav" class="popover bottom">
            <div class="popover-arrow"></div>
            <div class="nav nav-list">
              <p v-if="getTotal === 0">
                <br />
                購物車內沒有商品
              </p>
              <a v-for="(item, key) in getCartList" :key="key" :href="getCartTypeUrl(item.cartType)" v-else>
                {{ item.cartName }}
                <b class="value">
                  <span>{{ item.count }}</span>
                </b>
              </a>
            </div>
            <a :href="getCartMainUrl" class="btn btn-secondary btn-sm btn-block"> 結帳 </a>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { renderState, renderGetter } from '@/helpers/vuex';

const cartMainUrl = `${process.env.VUE_APP_CART}/cart/main`;

/**
 * 取得總數 & for  header cart list 狀態
 * @return {number}
 */
const getTotal = () => renderGetter('Cart', 'getInfoTotal');

/** 取得列表
 * return {Array}
 * */
const getCartList = () => renderState('Cart', 'cartInfo');

/**  導去 cartMain */
const getCartMainUrl = () => cartMainUrl;

/** 導去指定 cartType */
const getCartTypeUrl = (cartType) => `${cartMainUrl}?type=${encodeURIComponent(cartType)}`;

const computed = {
  getTotal,
  getCartList,
  getCartMainUrl,
};

const methods = {
  getCartTypeUrl,
};

export default {
  name: 'mini-cart',
  computed,
  methods,
};
</script>
