import { paymentEnum } from '@/constant/payment-info';
// 整份官方文件位置: https://developers.google.com/analytics/devguides/collection/analyticsjs/enhanced-ecommerce?hl=zh-cn

const CURRENCY = 'TWD';

const createProductObj = (products, checkOut) => {
  products.forEach((item) => {
    const obj = {
      name: item.productName,
      price: checkOut ? (typeof item.finalPrice === 'undefined' ? item.productPrice : item.finalPrice) : item.oriPrice,
      quantity: item.productQty,
      id: item.martCode || item.productRoute.replace('/mart/', ''),
      brand: item.productBrand,
      category: Object.keys(item.categories).join('/'),
      variant: item.productSpec,
    };
    if (item.couponId && checkOut === true) {
      obj.coupon = item.couponId;
    }
    ga('ec:addProduct', obj);
  });
};

export const sendSelectItemForGA = (mart) => {
  const arrayForSelectItem = [];
  const itemList = {
    item_name: mart.item_name, // required
    price: mart.finalPrice,
    item_id: mart.item_id, // required
    item_list_name: '商品收藏',
    index: mart.index,
  };
  arrayForSelectItem.push(itemList);
  gtag('event', 'select_item', {
    items: arrayForSelectItem,
    item_list_name: '商品收藏',
    item_list_id: '商品收藏',
  });
};

/**
 * checkOutToCart 的 option 參數
 * from order-process-response.js
 * @param {Number} payment type code
 * @return {String} option string
 */
export const checkPaymentType = (payment) => {
  switch (payment) {
    case paymentEnum.creditCardOnetime:
      return '信用卡一次付款';
    case paymentEnum.creditCardInstallment:
      return '信用卡分期付款';
    case paymentEnum.creditCardBonus:
      return '信用卡紅利折抵';
    case paymentEnum.ATMTransfer:
      return 'ATM轉帳';
    case paymentEnum.linePayment:
      return 'LINE Pay付款';
    case paymentEnum.jkosPayment:
      return '街口支付';
    case paymentEnum.PiPayment:
      return 'Pi拍錢包';
    case paymentEnum.PlusPayPayment:
      return '全盈+PAY';
    case paymentEnum.TWPayment:
      return '台灣Pay';
    case paymentEnum.ApplePayPayment:
      return 'Apple Pay';
    case paymentEnum.EasyPayPayment:
      return '悠遊付';
    case paymentEnum.ZingalaPayment:
      return '銀角零卡分期';
    case paymentEnum.storePayment:
      return '神腦門市繳費';
    default:
      return '其他';
  }
};

/**
 * delivery type
 * @param {String} delivery delivery type
 */
export const checkDelivery = (delivery) => {
  switch (delivery) {
    case '0':
      return '免配送';
    case '1':
      return '宅配到府';
    case '2':
      return '神腦門市取貨';
    default:
      return '其他';
  }
};

export function onShippingComplete(stepNum, option) {
  ga('ec:setAction', 'checkout', { step: stepNum, option });
  ga('send', 'event', '購物車頁', '配送資料填寫完成');
}

/**
 * addToCart 加入購物車
 * @param {Array} products 需要加入的產品
 * @param {string} martCode
 */
export function addToCart(products, martCode) {
  try {
    createProductObj(products, false);
    ga('ec:setAction', 'add');
    ga('send', 'event', '購物車頁', '加入購物車', martCode);
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(`addToCart error:${err}`);
  }
}

/**
 * removeFromCart 購物車中移除
 * @param {Array} products 需要移除的產品
 * @param {string} martCode
 */
export function removeFromCart(products, martCode) {
  try {
    createProductObj(products, false);
    ga('ec:setAction', 'remove');
    ga('send', 'event', '購物車頁', '移除購物車', martCode);
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(`removeFromCart error:${err}`);
  }
}

/**
 * checkOut 結帳 (main to step1)
 * @param {Array} products 產品們
 * @param {Number} stepNum 步驟編號
 * @param {String} option 額外選項 (payment || 空字串 ||delivery)
 * PC: step1 => checkout ; step3 => delivery & payment ; Mobile: step1 => step1 => checkout | step2: delivery | step3 => payment;
 * @param {Function} callBack 回傳 function
 * @return {boolean} return callback
 */
export function checkOutToCart(products, stepNum, option = '', callBack = function () {}) {
  if (Array.isArray(products) && products.length <= 0) {
    return false;
  }
  try {
    createProductObj(products, true);
    ga('ec:setAction', 'checkout', { step: stepNum, option });
    if (stepNum === 1) {
      ga.loaded ? ga('send', 'event', '購物車頁', '開始結帳', { hitCallback: () => callBack() }) : callBack();
    }
    return true;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(`removeFromCart error:${err}`);
  }
  return true;
}

/**
 * 訂單完成
 * @param {Array} products 產品們
 * @param {String} orderId 訂單編號
 * @param {String} amount 總金額
 * @param {String} shipping 運費
 * affiliation-文源: https://developers.google.com/analytics/devguides/collection/analyticsjs/field-reference?hl=zh_cn#(product_action)_affiliation
 */
export function purchase(products, orderId, amount, shipping) {
  if (Array.isArray(products) && products.length <= 0) {
    return false;
  }
  try {
    createProductObj(products, true);
    ga('ec:setAction', 'purchase', {
      id: orderId,
      affiliation: 'senao_online',
      revenue: amount,
      shipping,
    });
    ga('send', 'event', '購物車頁', '完成購買', orderId);
    return true;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(`purchase error:${err}`);
  }
  return true;
}

/**
 * 建立共用 parameter for GA4
 * @param {Array} cartDetail
 * @return {Array}
 * */
export function createParameterArray(cartDetail) {
  const arrayForSendEvent = [];
  cartDetail.map((item) => {
    if (item.cartTag === '2' || item.cartTag === '3') {
      return;
    }
    const itemList = {
      item_id: item.martCode,
      item_name: item.productName,
      item_variant: item.productNo,
      price: item.oriPrice,
      currency: CURRENCY,
      quantity: Number(item.productQty),
    };
    return arrayForSendEvent.push(itemList);
  });
  return arrayForSendEvent;
}

/**
 * 建立共用 parameter for GA4 - use for add to wishlist event
 * @param {Object} cartItem
 * @return {Object}
 * */
export function createParameterObj(item) {
  return {
    item_id: item.martCode,
    item_name: item.productName,
    item_list_id: 'Cart',
    item_list_name: '購物車',
    price: item.oriPrice,
    currency: CURRENCY,
    quantity: Number(item.productQty),
  };
}

/**
 * View promotion
 * @param {Array} marts 產品們
 * doc: https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtag#view_promotion
 */

export function sendPromotionForGA(marts) {
  if (Array.isArray(marts) && marts.length <= 0) {
    return false;
  }
  try {
    createMartObj(marts);
  } catch (err) {
    console.log(`sendPromotionForGA error:${err}`);
  }
}

/**
 * create Promotion parameters
 * @param {Object} banner
 * @return {Object}
 * */
export function createPromotionItem(banner) {
  const { creative_slot, promotion_id, items, alt } = banner;
  return {
    creative_name: 'ad',
    creative_slot,
    location_id: 'CART',
    promotion_id,
    promotion_name: alt,
    items,
  };
}

/**
 * create Registration parameters
 * @param {String} text
 * @param {String} location
 * @return {Object}
 * */
export function createRegistrationItem(text, location) {
  return {
    method: 'normal',
    location_id: location,
    action_text: text,
  };
}

/**
 * sendGA4sEvent
 * @param {string} eventName
 * @param {object} parameterForGA
 */
export function sendGA4sEvent(eventName, parameterForGA) {
  try {
    gtag('event', eventName, parameterForGA);
  } catch (err) {
    console.log(`sent GA4 event error:${err}`);
  }
}
