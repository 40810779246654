import { curry } from 'ramda';

export const newTheSame = function (source) {
  const newObject = {};
  Object.keys(source).map((key) => (newObject[key] = source[key]));
  return newObject;
};

/** 將 object 根據 mapping key 轉換成另外一個 object */
export const autoMap = (source, target, mapping) =>
  Object.entries(source).reduce((accm, [key, value]) => {
    accm[mapping[key] || key] = value;
    return accm;
  }, target);

/** 寫入 prop，適合 Ramda */
export const setProp = curry((prop, value, object) => (object[prop] = value));
