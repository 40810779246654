const expires = (min) => {
  const date = new Date();
  date.setTime(date.getTime() + min * 60 * 1000);
  return date.toUTCString();
};
const getFatherDomain = () => {
  const domain = document.domain.split('.');
  domain[0] = '';
  return domain.join('.');
};

export const setCookie = (name, value, minutes) => {
  document.cookie = `${name}=${value};expires=${expires(minutes)};domain=${getFatherDomain()};path=/;`;
};

export const getCookie = (cookieName) => {
  const name = `${cookieName}=`;
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};

export const getUtm = (utm) => {
  if (!utm) {
    return '';
  }
  const utmUrl = new URL(decodeURIComponent(utm));
  const kpiStoreId = utmUrl.searchParams.get('kpi_store_id');
  const utmSource = utmUrl.searchParams.get('utm_source');
  if (kpiStoreId) {
    return `kpi_store_id=${kpiStoreId}`;
  }
  if (utmSource) {
    return `utm_source=${utmSource}`;
  }
  return '';
};
