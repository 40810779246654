import axios from 'axios';
import { renderState, commitMutation } from '@/helpers/vuex';
import { setCookie } from '@/helpers/cookie';
import { xsrfKeyName, xsrfAlive, createToken } from '@/helpers/xsrf';

const withCredentials = process.env.VUE_APP_AXIOS_WITHCREDENTIALS === 'true';

// 開發環境多傳一個 site 做區別的判斷規則 (localhost 環境打 不論 api 是 lab 或 stg 都加上 site 參數)
const modeRule =
  window.location.href.indexOf('local') > -1 || process.env.VUE_APP_SENAO_ENV === 'development'
    ? ['stg', 'development']
    : ['development'];
const rule = modeRule.indexOf(process.env.VUE_APP_SENAO_ENV) > -1;

let loadingFlag = 0;

// 呼叫 API 前 show loading
function showLoading() {
  if (loadingFlag === 0) {
    commitMutation('Loading', 'updateLoading', true);
  }
  loadingFlag++;
}

// 呼叫完最後一支 API 後 hide loading
function hideLoading() {
  loadingFlag--;
  loadingFlag = Math.max(loadingFlag, 0);
  if (loadingFlag === 0) {
    commitMutation('Loading', 'updateLoading', false);
  }
}

/** 遇上 error 500 等錯誤時關閉 loading */
const errorResponse = (error) => {
  hideLoading();
  if (error.response && error.response.status && (error.response.status === 504 || error.response.status === 404)) {
    // eslint-disable-next-line no-console
    console.log('Gateway Time-out 或查無此頁');
  } else if (error.response && error.response.status && error.response.status === 403) {
    // eslint-disable-next-line no-console
    console.log('無權限');
  } else {
    // console.log('其他未知錯誤');
  }
  console.log(error);

  return Promise.reject(error);
};

/** 設定 Axios 使用 CORS 方式 */
export const setAxiosCORS = () => {
  axios.defaults.withCredentials = withCredentials;
  axios.defaults.crossDomain = true;
  axios.defaults.headers.get['Content-Type'] = 'application/x-www-form-urlencoded';
  axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

  // 送出請求前 -> xsrf 參數 & loading 畫面
  axios.interceptors.request.use((response) => {
    const postFormData = response.data instanceof FormData;
    const fetchUrl = response.url;
    const searchTerm = '/handle/payment/polling';
    const isShowLoading = response.data === undefined && fetchUrl.indexOf(searchTerm) === -1;
    // 如無特別寫入設定檔即開啟 loading
    if (isShowLoading || postFormData) {
      showLoading();
    }

    // 增加參數避開 undefined
    response.params = response.params || {};

    // 開發環境多傳一個 site 做區別
    if (rule) {
      response.params.site = encodeURI(`${process.env.VUE_APP_CART}`);
    }

    /** Xsrf token */
    // 未開啟 Xsrf 功能
    if (typeof response.data !== 'object' || !response.data.get('enabledXsrf')) {
      return response;
    }

    // 建立 token
    const token = createToken();

    // set Cookie
    setCookie(xsrfKeyName, token, xsrfAlive);

    // 參數加入 xsrfToken
    response.params[xsrfKeyName] = token;

    return response;
  });

  // 接收請求前  -> 關掉 loading 畫面
  axios.interceptors.response.use((response) => {
    const loadingState = renderState('Loading', 'isLoading');
    if (loadingState) {
      hideLoading();
    }
    return response;
  }, errorResponse);
};
