import axios from 'axios';
import { toKey } from '@/helpers/promise';

const API_PATH = `${process.env.VUE_APP_FRONT_API}${process.env.VUE_APP_QUICK_SETTLE}`;

/** 取得快速刷卡資訊 */
export default (() => {
  const cache = {};
  return (args) => {
    const key = toKey(args);
    if (cache[key]) return new Promise((resolve) => resolve(cache[key]));

    return axios.get(API_PATH, { params: args }).then((res) => (cache[key] = res));
  };
})();
