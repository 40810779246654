import { curry, prop } from 'ramda';
import store from '@/store/index';
import { capitalize } from '@/helpers/string';

/** 建立 mutation() */
export const makeMutation = curry((name, state, payload) => (state[name] = payload));

/** commit 多 mutations */
export const commitMutations = curry((module, mutation) =>
  Object.entries(mutation).forEach((x) => store.commit(`${module}/${`set${capitalize(x[0])}`}`, x[1]))
);

/** commit 多 mutations, 適合 Ramda */
export const getMutations = curry(
  (module, mutation) => () =>
    Object.entries(mutation).forEach((x) => store.commit(`${module}/${`set${capitalize(x[0])}`}`, x[1]))
);

/** commit 單一 mutation */
export const commitMutation = curry((module, state, value) =>
  store.commit(`${module}/${`set${capitalize(state)}`}`, value)
);

/** 單一 mutation, 適合 method, Ramda */
export const getMutation = curry(
  (module, state, value) => () => store.commit(`${module}/${`set${capitalize(state)}`}`, value)
);

/** 直接呼叫特定 mutation */
export const callMutation = curry((module, mutation, payload = {}) => store.commit(`${module}/${mutation}`, payload));

/** 直接呼叫特定 mutation，適合 method, watch, Ramda */
export const applyMutation = curry(
  (module, mutation, payload = {}) =>
    () =>
      store.commit(`${module}/${mutation}`, payload)
);

/** 讀取 Vuex 的 state */
export const renderState = curry((module, state) => store.state[module][state]);

/** 讀取 Vuex 的 getter */
export const renderGetter = curry((module, getter) => store.getters[`${module}/${getter}`]);

/** 讀取 Vuex 的 state, 適合 computed, Ramda */
export const getState = curry((module, state) => () => store.state[module][state]);

/** 讀取 Vuex 的 getter, 適合 computed, Ramda */
export const getGetter = curry((module, getter) => () => store.getters[`${module}/${getter}`]);

/** 在 Vuex 內讀取其他 module 的 state，回傳為 function */
export const makeGetterByState = curry(
  (module, stateName) => (state, getters, rootState) => rootState[module][stateName]
);

/** 在 Vuex 內讀取其他 module 的 getter，回傳為 function */
export const makeGetterByGetter = curry(
  (module, getter) => (state, getters, rootState, rootGetters) => rootGetters[`${module}/${getter}`]
);

/** 執行 action */
export const dispatchAction = curry((module, action, payload = {}) => store.dispatch(`${module}/${action}`, payload));

/** 執行 action, 適合 mounted, Ramda */
export const getAction = curry(
  (module, action, payload = {}) =>
    () =>
      store.dispatch(`${module}/${action}`, payload)
);

/** 產生 computed 所需要的 getter() */
const makeAdvGetter = (index) =>
  function () {
    return renderState(this.module, this.states[index]);
  };

/** 產生 computed 所需要的 setter() */
const makeAdvSetter = (index) =>
  function (value) {
    commitMutation(this.module, this.states[index], value);
  };

/** 動態產生 v-model 所需要的 computed object */
export const createAdvComputed = (index) => ({
  get: makeAdvGetter(index),
  set: makeAdvSetter(index),
});

/** 產生 computed 所需要的 getter() */
const makeGetter = curry((module, state) => () => renderState(module, state));

/** 產生 computed 所需要的 setter() */
const makeSetter = curry((module, state, value) => commitMutation(module, state, value));

/** 動態產生 v-model 所需要的 computed object */
export const createComputed = curry((module, state) => ({
  get: makeGetter(module, state),
  set: makeSetter(module, state),
}));

/** 將 state 值由 true 轉 false，false 轉 true */
export const toggleState = curry((module, state) => {
  const data = renderState(module, state);
  commitMutation(module, state, !data);
});

/** 建立 dynamic component，且寫回 store */
export const createDynamicComponent = (module, state, component, lut) => ({
  get: () => renderState(module, state),
  set: (value) => {
    commitMutation('DynamicComponents', component, prop(value, lut));
    commitMutation(module, state, value);
  },
});
