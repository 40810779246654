import axios from 'axios';

/** 取得特約中心門市資訊 */
const STORE_CENTER = `${process.env.VUE_APP_FRONT_API}${process.env.VUE_APP_FRONT_SERVICE_CENTER}`;

/** 神腦門市地址 */
const STORE_INFO = `${process.env.VUE_APP_FRONT_API}${process.env.VUE_APP_FRONT_STORE_INFO}`;

/** 取得預設取貨門市資訊 */
const GET_STORE = `${process.env.VUE_APP_FRONT_API}${process.env.VUE_APP_FRONT_GET_STORE}`;

const setConfig = (path = '') => {
  const config = {
    method: 'GET',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    url: path,
  };

  return config;
};

/** 取得特約中心門市資訊 */
export const fetchCityTownServiceCenter = () => axios(setConfig(STORE_CENTER));

/** 單一門市資料 */
export const getStoreInfo = (storeId = '') => axios(setConfig(`${STORE_INFO}/${storeId}`));

/** 取得預設取貨門市資料 */
export const getStore = () => axios.get(GET_STORE);
