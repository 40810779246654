import { assoc, chain, map, prop } from 'ramda';
import {
  defaultCity,
  defaultCityTownStores,
  defaultStore,
  defaultTown,
} from '@/store/modules/step1/constant/city-town-stores.constant';
import { fetchCityTownServiceCenter, getStore, getStoreInfo } from '@/api/step1/city-town-service-center';
import { makeMutation, renderGetter } from '@/helpers/vuex';
import { getStoreFromObj, geocodeAddress, loadGoogleMapsAPI } from '@/helpers/getNearbyStore';

/** 動態城市 */
const cities = ({ allCityTownStores: { cities } }) => [defaultCity, ...cities];

/** 動態鄉鎮區 */
const towns = ({ cityIndex }, { cities }) => [defaultTown, ...cities[cityIndex].districts];

/** 動態門市 */
const stores = ({ townIndex }, { towns }) => [
  defaultStore,
  ...map(chain(assoc('name'), prop('storeName')), towns[townIndex].stores),
];

/** 產生 getter */
const makeGetter =
  (x) =>
  ({ storeIndex }, { stores }) =>
    prop(x, stores[storeIndex]);

/** 是否顯示 '地址' 區塊 */
const isShowAddress = (state, { county }) => county !== undefined;

/** 儲存 [特約中心門市] 資訊與判斷流程 */
const saveAllCityTownStores = ({ data: { data } }, commit) => {
  // 篩出全台門市的經緯度
  const allStorePosition = [];
  // 另存全台門市的 store info 用來回溯用
  const allStoreInfo = [];

  const newCities = data.cities.filter((city) => {
    const newCity = city.districts.filter((town) => {
      const newTown = town.stores.filter((store) => store.isIsland === false);
      const newTownModify = newTown.map((store) => {
        store.zipcode = store.zipcode.substring(0, 3);
        const newWeeklist = store.weekList.map((item) => {
          if (item.time === '') {
            item.time = '休息';
          }
          return item;
        });
        store.weekList = newWeeklist;
        allStorePosition.push([store.lat, store.lon]);
        allStoreInfo.push(store);
        return store;
      });
      return newTownModify.length > 0;
    });
    return newCity.length > 0;
  });
  // 存取本島門市
  commit('setAllCityTownStores', { cities: newCities });
  commit('setAllStorePosition', allStorePosition);
  commit('setAllStoreInfo', allStoreInfo);
};

/** [取得特約中心門市資訊] API */
const getAllCityTownStores = ({ commit }) => {
  const cities = renderGetter('CityTownStores', 'cities');
  /** 第二次之後直接回拋 store 內容不打 api */
  if (cities.length > 0 && ['請選擇', ''].indexOf(cities[1].name) === -1) {
    return cities;
  }
  return fetchCityTownServiceCenter()
    .then((res) => saveAllCityTownStores(res, commit))
    .catch(function (error) {
      console.log(error);
      alert('無法取得門市列表！');
      this.$emit('change-mode', 'step1-store');
      this.$router.push('/m/cart/choice');
    });
};

/** 儲存[單一門市資訊] 資訊並寫入資料 */
const saveStoreInfo = (res) => {
  // 檢查是否取得成功
  if (!res.data.data) {
    return;
  }
  const { storeId, county, district, storeName } = res.data.data;
  getStoreFromObj(storeId, county, district, storeName);
};

/** 取得單一門市資訊 */
const getGetStoreInfo = ({
  rootState: {
    RecipientInfo: { senaoStoreId, selectStore },
  },
}) => {
  // 避免重複呼叫
  if (selectStore[0].storeId === senaoStoreId) {
    return selectStore[0];
  }
  return (
    getStoreInfo(senaoStoreId)
      .then(saveStoreInfo)
      // eslint-disable-next-line no-console
      .catch((e) => console.log(e))
  );
};

/** 儲存 [取得預設取貨門市] 資訊與判斷流程 */
const saveGetStoreInfo = (res) => {
  // 檢查是否取得成功
  if (!res.data.data) {
    return;
  }
  const hasStoreInfoFlag = Object.keys(res.data.data).includes('store');
  // 表示上次訂單裡有門取資料 ; 第二優先
  if (hasStoreInfoFlag) {
    const { id, name, city, district } = res.data.data.store;
    getStoreFromObj(id, city, district, name);
    return;
  }
  // 最後才是拿訂購人地址尋找最近門市
  loadGoogleMapsAPI.then(() => geocodeAddress());
};

/** 取得預設取貨門市API */
const getGetStore = ({
  rootState: {
    RecipientInfo: { senaoStoreId },
  },
  state: { isCoordinate },
}) => {
  // 避免重複呼叫
  if (senaoStoreId !== '' || isCoordinate) {
    return;
  }
  getStore()
    .then(saveGetStoreInfo)
    // eslint-disable-next-line no-console
    .catch((e) => console.log(e));
};

export default {
  namespaced: true,
  state: {
    /** 特約中心門市資訊 */
    allCityTownStores: defaultCityTownStores,
    /** 動態城市 index */
    cityIndex: 0,
    /** 動態鄉鎮 index */
    townIndex: 0,
    /** 動態門市 index */
    storeIndex: 0,
    /** 儲存全台門市的經緯度 */
    allStorePosition: [],
    /** 儲存全台門市資料 */
    allStoreInfo: [],
    /** 是否有 call 過 serviceCenter API & getStore API */
    isCalledStoreAPI: false,
    /** 是否有經緯度資料 from setCartCheckOut API */
    isCoordinate: false,
  },
  getters: {
    cities,
    towns,
    stores,
    /** 顯示門市名稱 */
    store: makeGetter('name'),
    /** 顯示門市id */
    storeId: makeGetter('storeId'),
    /** 顯示地址的 '市' */
    county: makeGetter('county'),
    /** 顯示地址的 '地區' */
    town(state, getters) {
      return getters.towns[state.townIndex].name || '';
    },
    /** 顯示地址 */
    address: makeGetter('address'),
    /** 顯示全地址 */
    fullAddress: makeGetter('fullAddress'),
    /** typeName */
    typeName: makeGetter('typeName'),
    /** 營業項目 */
    serviceItems: makeGetter('serviceItems'),
    /** 聯絡電話 */
    tel: makeGetter('tel'),
    /** 位置:經度 */
    lat: makeGetter('lat'),
    /** 位置:緯度 */
    lng: makeGetter('lon'),
    isShowAddress,
    weekList: makeGetter('weekList'),
    zipcode: makeGetter('zipcode'),
  },
  mutations: {
    setAllCityTownStores: makeMutation('allCityTownStores'),
    setCityIndex: makeMutation('cityIndex'),
    setTownIndex: makeMutation('townIndex'),
    setStoreIndex: makeMutation('storeIndex'),
    setAllStorePosition: makeMutation('allStorePosition'),
    setAllStoreInfo: makeMutation('allStoreInfo'),
    setIsCalledStoreAPI: makeMutation('isCalledStoreAPI'),
    setIsCoordinate: makeMutation('isCoordinate'),
  },
  actions: {
    getAllCityTownStores,
    getGetStoreInfo,
    getGetStore,
  },
};
