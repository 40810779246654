/** 若 [配送方式] 找不到，所提供的預設值 */
export const defaultShipAmount = {
  /** 是否可分期 */
  isInstallment: 0,
  /** 是否含運費 */
  isShipFare: 0,
  /** 配送方式 */
  delivery: '0',
  /** 本次實際運費 */
  realShipFare: 0,
  /** 本次實付金額 */
  amount: 0,
};
