import { applySpec, prop, nth, compose, ifElse, equals, when, gt, __, defaultTo } from 'ramda';
import {
  makeGetterByGetter,
  makeGetterByState,
  makeMutation,
  getGetter,
  applyMutation,
  renderState,
  dispatchAction,
  commitMutation,
} from '@/helpers/vuex';
import { paymentStatusEnum, isinstallmentEnum } from '@/constant/payment-info';
import fetchInstallment from '@/api/step1/best-installment';

/** 由 [AmountDetail/realShipFare] 來的 [本次實際運費] */
const realShipFare = makeGetterByGetter('AmountDetail', 'realShipFare');

/** 由 [AmountDetail/checksum] 來的 [checksum] */
const checksum = makeGetterByState('AmountDetail', 'checksum');

/** 處理過的 [選擇分期 index]，因為有 null */
const index = compose(defaultTo(0), prop('installmentIndex'));

/** 目前所選擇分期 */
const period = ({ installments }, { index }) => compose(prop('period'), nth(index))(installments);

/** 目前所選分期之每月金額 */
const moneyPerMonth = ({ installments }, { index }) => compose(prop('money'), nth(index))(installments);

/** 產生 [取得信用卡分期] API 所需要的參數 */
/** {k: v} -> {k: v}
 *  @return {object}
 * */
const createArgs = applySpec({
  deductAmount: () =>
    parseInt(
      renderState('AmountDetail', 'deductedVCashState') +
        renderState('AmountDetail', 'deductedHamiPointState') +
        renderState('AmountDetail', 'deductedEdenredState'),
      10
    ),
  shipFare: prop('realShipFare'),
  checksum: prop('checksum'),
  groups: () => JSON.stringify(renderState('InstallmentInfo', 'categories')),
});

/** 儲存 [所有分期資料] */
const saveInstallments =
  (commit) =>
  ({
    data: {
      data: { installments },
    },
  }) => {
    if (installments !== undefined && installments.length > 0) {
      commit('setInstallments', installments);
      commitMutation('PaymentInfo', 'installment', prop('enable', paymentStatusEnum));
    } else {
      // 若分期陣列為空時鎖死分期選項狀態
      dispatchAction('PaymentInfo', 'disableInstallment');
    }
  };

/** 目前 [配送方式] 的 [信用卡分期] 是否為 visible */
/** PaymentInfo/installmentStatus > 0 則為 visible */
const isInstallmentStatusVisible = (rootGetters) =>
  compose(gt(__, 0), () => rootGetters['PaymentInfo/installmentStatus']);

/** 信用卡可分期相關 mutation */
/** payments 裡有可分期選項才能 call getBest */
const mutateInstallable =
  (commit, getters, rootGetters) =>
  // 如果是可分期 OR 非零元訂單才能 call getBest
  () =>
    rootGetters['PaymentInfo/installmentStatus'] !== undefined || rootGetters['AmountDetail/realInstantAmount'] > 0
      ? fetchInstallment(createArgs(getters)).then(saveInstallments(commit))
      : '';

/** 信用卡可分期流程 */
/** 當 [配送方式] 的 [信用卡分期] 為 visible 時，才會啟動 [信用卡分期相關 mutation] */
/** 更新: 有分期付款選項才可 call 分期API */
const installable = (commit, getters, rootState, rootGetters) =>
  when(isInstallmentStatusVisible(rootGetters), mutateInstallable(commit, getters, rootGetters, rootState));

/** 當 [配送方式] 的 [信用卡分期] 為 visible 時，才會啟動 [信用卡不分期相關 mutation] */
const mutateInstallment = (rootGetters) =>
  when(
    isInstallmentStatusVisible(rootGetters),
    applyMutation('PaymentInfo', 'setInstallment', prop('disable', paymentStatusEnum))
  );

/** 信用卡不可分期流程 */
/** 若 [信用卡分期] 為 visible 時，才會啟動 [信用卡不分期相關 mutation] */
/** 回到 [預設付款方式] 與對應的 [付款方式代碼] */
const notInstallable = (state, getters, rootState, rootGetters) =>
  compose(
    applyMutation('PaymentInfo', 'setPayment', renderState('PaymentInfo', 'payment')),
    applyMutation('DynamicComponents', 'setPaymentMethod', renderState('DynamicComponents', 'paymentMethod')),
    mutateInstallment(rootGetters)
  );

/** 由 [本次是否可分期] 決定是否要打 [取得信用卡分期] API */
/** 若 [本次是否可分期] 為 1, 則表示可分期，可打 [取得信用卡分期] API */
/** 否則將 [信用卡分期] 顯示但 disable */
const pickInstallment = (commit, getters, rootState, rootGetters) =>
  ifElse(
    equals(prop('installable', isinstallmentEnum)),
    installable(commit, getters, rootState, rootGetters),
    notInstallable(commit, getters, rootState, rootGetters)
  );

/** [取得信用卡分期] API */
/** 本次是否可分期 */
/** 這裡的改變，會觸發 real-amount.vue 的 watch 改變 */
/** 用在「是否福利金全抵」情境
 * ['AmountDetail/isInstallment'] = 1 表示有分期選項 */
const getInstallments = ({ commit, getters, rootState, rootGetters }) =>
  compose(pickInstallment(commit, getters, rootState, rootGetters), getGetter('AmountDetail', 'isInstallment'))();

/** 修改 [所有分期資料] */
const setInstallments = makeMutation('installments');

/** 修改 [選擇分期 index] */
const setInstallmentIndex = makeMutation('installmentIndex');

/** 修改 [滑鼠滑過的分期] */
const setHoveredInstallment = makeMutation('hoveredInstallment');

/** 修改 [金額及分類] (設定購物車結帳 API 取得) */
const setCategories = makeMutation('categories');

export default {
  namespaced: true,
  state: {
    /** 所有分期資料 */
    installments: [
      {
        money: 0,
        period: 0,
        rate: 0,
        banks: [],
      },
    ],
    /** 選擇分期 index */
    installmentIndex: 0,
    /** 滑鼠滑過的分期 */
    hoveredInstallment: 0,
    /** 金額及分類 (設定購物車結帳 API 取得) */
    categories: [
      {
        amounts: '',
        category: '',
      },
    ],
  },
  getters: {
    realShipFare,
    index,
    checksum,
    period,
    moneyPerMonth,
  },
  mutations: {
    setInstallments,
    setInstallmentIndex,
    setHoveredInstallment,
    setCategories,
  },
  actions: {
    getInstallments,
  },
};
