import { makeMutation } from '@/helpers/vuex';
import { getCityIdByName, getTownIdByName } from '@/helpers/city-town';

/** 修改 [付款人姓名] */
const setName = makeMutation('name');
const name = (state, value) => (state.name = value);

const setIsHasOrderName = makeMutation('isHasOrderName');

/** 修改 [付款人手機] */
const setMobilePhone = makeMutation('mobilePhone');
const mobilePhone = (state, value) => (state.mobilePhone = value);

const setIsHasOrderMobile = makeMutation('isHasOrderMobile');

/** 修改 [付款人電子郵件] */
const setEmail = makeMutation('email');
const email = (state, value) => (state.email = value);

const setIsHasOrderMail = makeMutation('isHasOrderMail');

/** 修改 [郵遞區號] */
const setAddressZip = makeMutation('addressZip');

/** 修改 [付款人居住城市 ID] */
const setAddressCityId = makeMutation('addressCityId');

/** 修改 [付款人居住城市] */
const setAddressCity = makeMutation('addressCity');

/** 修改 [付款人居住地區 ID] */
const setAddressTownId = makeMutation('addressTownId');

/** 修改 [付款人居住地區] */
const setAddressTown = makeMutation('addressTown');

/** 修改 [付款人居住地址] */
const setAddress = makeMutation('address');

const setFullAddress = makeMutation('fullAddress');

const setIsHasAddress = makeMutation('isHasAddress');

const setIsHasZipCode = makeMutation('isHasZipCode');

/** 根據 [付款人居住城市] 更新 [付款人居住城市 ID] */
const updateAddressCityId = (state) => {
  state.addressCityId = getCityIdByName({ cityName: state.addressCity });
};

/** 根據 [付款人居住地區] 更新 [付款人居住地區 ID] */
const updateAddressTownId = (state) => {
  state.addressTownId = getTownIdByName({
    cityName: state.addressCity,
    townName: state.addressTown,
  });
};

const setUserPickGender = makeMutation('userPickGender');
const setUserPickBirthYear = makeMutation('userPickBirthYear');
const setUserPickBirthMonth = makeMutation('userPickBirthMonth');
const setUserPickBirthDate = makeMutation('userPickBirthDate');
const setUserAgreeTerms = makeMutation('userAgreeTerms');

export default {
  namespaced: true,
  state: {
    /** 由 [設定購物車結帳] API 取得 [付款人姓名] */
    name: '',
    /** 會員中心裡是否已存在訂購人姓名 */
    isHasOrderName: false,
    /** 由 [設定購物車結帳] API 取得 [付款人手機] */
    mobilePhone: '',
    isHasOrderMobile: false,
    /** 由 [設定購物車結帳] API 取得 [付款人電子郵件] */
    email: '',
    isHasOrderMail: false,
    /** [郵遞區號] */
    addressZip: '',
    /** 付款人居住城市 ID */
    addressCityId: '',
    /** 由 [設定購物車結帳] API 取得 [付款人居住城市] */
    addressCity: '',
    /** 付款人居住地區 ID */
    addressTownId: '',
    /** 由 [設定購物車結帳] API 取得 [付款人居住地區] */
    addressTown: '',
    /** 由 [設定購物車結帳] API 取得 [付款人居住地址] */
    address: '',
    // use for google map API
    fullAddress: '',
    /** 會員中心裡是否已存在訂購人詳細地址 */
    isHasAddress: false,
    /** 會員中心裡是否已存在訂購人郵遞區號 */
    isHasZipCode: false,
    userPickGender: '',
    userPickBirthYear: '',
    userPickBirthMonth: '',
    userPickBirthDate: '',
    userAgreeTerms: false,
    /** 偵測有無點選同訂購人資訊 / 使用收件人記事本按鈕 */
    isClickButtons: false,
  },
  mutations: {
    setName,
    name,
    email,
    mobilePhone,
    setIsHasOrderName,
    setMobilePhone,
    setIsHasOrderMobile,
    setEmail,
    setIsHasOrderMail,
    setAddressZip,
    setAddressCityId,
    setAddressCity,
    setAddressTownId,
    setAddressTown,
    setAddress,
    setFullAddress,
    setIsHasAddress,
    setIsHasZipCode,
    updateAddressCityId,
    updateAddressTownId,
    setUserPickGender,
    setUserPickBirthYear,
    setUserPickBirthMonth,
    setUserPickBirthDate,
    setUserAgreeTerms,
    setIsClickButtons: makeMutation('isClickButtons'),
  },
};
