import { find, propEq } from 'ramda';
import { dispatchAction, commitMutation } from '@/helpers/vuex';
import { sendGA4sEvent } from '@/helpers/tracking/ga';
import { isMobile } from '@/helpers/is-from-app';
import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

/**
 * only available in html5 history mode
 * 当切换到新路由时，想要页面滚到顶部，或者是保持原先的滚动位置，就像重新加载页面那样。
 * defaults to no scroll behavior
 * return false to prevent scroll
 */
const scrollBehavior = (to, from, savedPosition) => {
  if (savedPosition) {
    // savedPosition is only available for popstate navigations.
    return savedPosition;
  }
  const position = {};
  // new navigation.
  // scroll to anchor by returning the selector
  if (to.hash) {
    position.selector = to.hash;
  }
  // 如果meta中有scrollTop
  if (to.matched.some((m) => m.meta.scrollToTop)) {
    // cords will be used if no selector is provided,
    // or if the selector didn't match any element.
    position.x = 0;
    position.y = 0;
  }
  // if the returned position is falsy or an empty object,
  // will retain current scroll position.
  return position;
};

/** 網址與裝置判斷不符時轉跳 */
function checkRootRedirect(to, from, next) {
  const isMobilePath = (to.fullPath.split('/').filter((x) => x.length > 0)[0] || '') === 'm';
  // 是手機或神腦購且網址是 for desktop
  if (isMobile() && !isMobilePath) {
    return next('/m/cart/choice');
  }
  // 是桌機且網址為 for mobile
  if (!isMobile() && isMobilePath) {
    return next('/cart/main');
  }

  // 清除結尾為 "/" 的路徑
  const toPath = to.path.substring(0, to.path.length - 1);
  if (to.path.substr(-1) === '/' && to.path !== from.path && toPath !== '') {
    return next(to.path.substring(0, to.path.length - 1));
  }
  return next();
}

export const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior,
  routes: [
    {
      path: '/',
      name: 'index',
      redirect: '/cart/main',
      component: () => import('@/components/common/choice-empty.vue'),
    },
    {
      path: '/cart',
      name: 'cartMain',
      redirect: '/cart/main',
      component: () => import('@/views/cart/main.vue'),
      children: [
        {
          path: 'main',
          meta: { title: '購物車-神腦生活' },
        },
      ],
    },
    {
      path: '/cart/finished',
      name: 'finished',
      meta: { scrollToTop: true, title: '結帳完成-神腦生活' },
      component: () => import('@/views/cart/cart-finished.vue'),
    },
    {
      path: '/cart/step1',
      name: 'step1',
      meta: { scrollToTop: true, title: '結帳-神腦生活' },
      component: () => import('@/views/step1/step1.vue'),
    },
    {
      path: '/cart/pluspay',
      name: 'pluspay',
      meta: { scrollToTop: true },
      component: () => import('@/views/step1/pluspay-qrcode.vue'),
    },
    {
      path: '/m/cart/choice',
      name: 'mobileCartChoice',
      meta: { scrollToTop: true, title: '購物車-神腦生活' },
      component: () => import('@/views/mobile/choice.vue'),
    },
    {
      path: '/m/cart/main',
      name: 'mobileCartMain',
      meta: { scrollToTop: true, title: '購物車-神腦生活' },
      component: () => import('@/views/mobile/main.vue'),
    },
    {
      path: '/m/cart/step1',
      name: 'mobileCartStep1',
      meta: { scrollToTop: true, title: '結帳-神腦生活' },
      component: () => import('@/views/mobile/step1.vue'),
    },
    {
      path: '/m/cart/step2',
      name: 'mobileCartStep2',
      meta: { scrollToTop: true, title: '結帳-神腦生活' },
      component: () => import('@/views/mobile/step2.vue'),
    },
    {
      path: '/m/cart/finished',
      name: 'mobileCartFinished',
      meta: { scrollToTop: true, title: '結帳完成-神腦生活' },
      component: () => import('@/views/mobile/finished.vue'),
    },
    /** choice-empty page */
    {
      path: '*',
      name: 'choiceEmpty',
      redirect: '/cart/main',
      component: () => import('@/components/common/choice-empty.vue'),
    },
    /** mobile cart choice page */
    {
      path: '/m/*',
      name: 'mobileChoiceEmpty',
      redirect: 'm/cart/choice',
      component: () => import('@/views/mobile/choice.vue'),
    },
  ],
});

/**
 * 比對資料
 * @var {String} path 目前網址
 * @var {String[]} from 來源網址
 * @var {String} name 返回網址
 */
// eslint-disable-next-line no-unused-vars
const checkData = [
  {
    path: '/cart/step1',
    from: ['/cart/main'],
    back: '/cart/main',
  },
  {
    path: '/m/cart/step2',
    from: ['/m/cart/main', '/m/cart/step1'], // 電子票券不需要 step1 收件人&地址
    back: '/m/cart/choice',
  },
  {
    path: '/cart/pluspay',
    from: ['/cart/step1'],
    back: '/cart/main',
  },
];

/** 判斷進入來源 */
// eslint-disable-next-line no-unused-vars
function checkToGo(to, from, next) {
  const toPath = typeof to.path === 'string' ? to.path.toLowerCase() : '';
  const fromPath = typeof from.path === 'string' ? from.path.toLowerCase() : '';
  const findData = find(propEq('path', toPath))(checkData);
  /** 若為資料中 */
  if (findData !== undefined && findData.path !== undefined && findData.from.indexOf(fromPath) <= -1) {
    return next({ path: findData.back, replace: true });
  }

  // 1. 從 step1 回 cartMain 時啟動 call cartDetail API
  // 2. 切換 cartMain Tab 時 call cartDetail API
  if (fromPath === toPath) {
    commitMutation('CartDetail', 'cartType', to.query.type);
    dispatchAction('CartDetail', 'fetchCartDetail');
  }
  return next();
}

function handlePageNaming(to, from, failure) {
  if (!failure) {
    const title = to.meta.title || '神腦生活';
    document.title = title;
    sendGA4sEvent('PageView', {
      page_name: title.split('-')[0],
    });
  }
}
/** tracking event */
// const trackingCode = to => {
//   // ga
//   ga('set', 'page', to.fullPath);
//   ga('send', 'pageview');
//   // facebook pixel
//   fbq('track', 'PageView');
// };

// 依來源判斷是否能進入該畫面
router.beforeEach(checkRootRedirect);
router.beforeEach(checkToGo);
router.afterEach(handlePageNaming);
// router.afterEach(trackingCode);

export default router;
