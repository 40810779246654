/**
 * common-city-town-store.vue 所使用的 default value
 * 預設空 object，避免 promise 非同步還執行時錯誤
 * */
export const defaultCityTownStores = {
  cities: [
    {
      name: '',
      districts: [
        {
          name: '',
          stores: [
            {
              storeName: '',
            },
          ],
        },
      ],
    },
  ],
};

export const defaultCity = {
  name: '請選擇',
  districts: [],
};

export const defaultTown = {
  name: '請選擇',
  stores: [],
};

/** '請選擇' 不在 JSON 內，需手動新增 */
export const defaultStore = {
  name: '請選擇',
};
