<template>
  <header class="header-v2">
    <!-- ### 通用連結 -->
    <TopBar />

    <!-- ### 主選單 -->
    <NavBar></NavBar>
  </header>
</template>

<script>
import NavBar from '@/components/desktop/Header/navbar/nav-bar.vue';
import TopBar from '@/components/desktop/Header/topbar.vue';

export default {
  components: {
    TopBar,
    NavBar,
  },
};
</script>
