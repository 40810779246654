import { defaultTo, find, nth, compose, propEq, prop, applySpec } from 'ramda';
import { defaultShipAmount } from '@/store/modules/step1/constant/amount-detail.constant';
import fetchVCash from '@/api/step1/vcash';
import fetchShipFare from '@/api/step1/ship-fare';
import fetchInstallment from '@/api/step1/best-installment';
import { commitMutation, dispatchAction, getState, makeMutation, getGetter, renderState } from '@/helpers/vuex';
import { router } from '@/pages/default/router';
import { paymentStatusEnum } from '@/constant/payment-info';
import { edenredBalanceTotal } from '@/helpers/edenred';
import { isMobile } from '@/helpers/is-from-app';
import store from '@/store/index';

const defaultState = {
  /** 小計 ; 該車全部商品小計原始總和 (未扣紅利或折價券) */
  subTotal: 0,
  /** 神腦幣 */
  bonusPoint: 0,
  /** 由 [設定購物車結帳] API 取得 [活動折抵/整車折抵金額] */
  cartDiscount: 0,
  /** [應付總金額] 不含 [運費] ; 前端計算的應付金額 (已扣ex: 紅利、折價券...折扣的金額) 同於 cartAmount from total-amount */
  totalAmount: 0,
  /** 福利金 + hamipoint + 宜睿 for 運費 API 的參數 */
  vCash: 0,
  hamiPoint: 0,
  /** 由 [取得運費 + 應付金額] API 取得 [運費] */
  shipFare: 0,
  /** 由 [取得運費 + 應付金額] API 取得 [應付金額] */
  amounts: [],
  /** 由 [取得運費 + 應付金額] API 取得 [免運費門檻] */
  shipFareFreeLimit: 0,
  /** 由 [取得運費 + 應付金額] API 取得 [分期門檻] */
  /** 用於判斷是否顯示分期提示訊息 */
  /** 更新: 原先為$490 ，改為另寫的判斷，依 getBest API 回傳內容決定，如果空陣列即分期選項 disable */
  /** 更新: 原先使用 installmentLimit 來判斷能否呼叫分期 API, 因後期呼叫條件改變，目前寫死為 0 影響 saveShipFare ()
   * 裡的 isInstallment 都會為 1， 導致進結帳頁預設呼叫分期 API。 */
  installmentLimit: 0,
  /** 由 [設定購物車結帳] API 取得 [明細總金額] */
  totalPrice: 0,
  /** 由 [設定購物車結帳] API 取得 [折扣後的應付金額] (不含運費) */
  cartAmount: 0,
  /** 由 [設定購物車結帳] API 取得 [優惠券折抵金額] */
  itemCoupon: 0,
  /** 由 [設定購物車結帳] API 取得 [折抵神腦幣] */
  usedPoint: 0,
  /** 由 [設定購物車結帳] API 取得 [神腦幣比率] */
  pointRate: 0,
  /** 由 [設定購物車結帳] API 取得 [是否整車電子票券] */
  isVTP: 0,
  /** 由 [設定購物車結帳] API 取得 [明細異動 checksum] */
  checksum: '',
  /** 由 [設定購物車結帳] API 取得 [CSRF] */
  csrf: {
    token: '',
    hash: '',
  },
  /** 有無週期購商品 */
  isCycle: 0,
  /** from partial-checkOut() ; 第二次呼叫運費 API 時使用 */
  cartType: '',
  isInstantAmount: 1,
  /** for instantAmount 即時運算用: deductedVCashState, deductedHamiPointState, deductedEdenredState
   * for 運費 API & 分期 API 的參數 */
  deductedVCashState: 0,
  deductedHamiPointState: 0,
  deductedEdenredState: 0,
  hamipointObj: {},
  VCashObj: {},
  edenredObj: {},
  getDiscountList: [],
};

/** 本次應付總金額 (含運費) = 應付總金額 + 運費
 * totalAmount + realShipFare ; 前端即時運算用
 * for realVCash|| realHamiPointAmount 計算用 & /process API POST flag
 * PC: discount-item-block.vue */
const realTotalAmount = ({ totalAmount }, { realShipFare }) => totalAmount + realShipFare;

/** 即時運算本次實付金額 */
const realInstantAmount = ({ deductedVCashState, deductedHamiPointState, deductedEdenredState }, { realTotalAmount }) =>
  realTotalAmount - deductedVCashState - deductedHamiPointState - deductedEdenredState;

/** 重新計算過運費的實付金額 ; 新增 realShipFare flag (bool)，目的: 較好取用運費值
 * @return {Array}
 */
const realShipAmounts = ({ amounts, shipFare }) =>
  amounts.map((x) => x.map((x) => ({ ...x, realShipFare: x.isShipFare && shipFare })));

/** 重新計算 [本次實付金額]  狀態: 原始值為 api 給的
 *  當 user 取消勾選使用 [福利金] 或 [hamipoint] 或選 [門取](一般配送車)時，前端即時運算 [本次實付金額] 加回折抵金額
 *  [配送方式] 會影響是否需要 [運費]
 *  第一層 array 的 index 為 [即時運算的 realInstantAmount 是否 < 0]
 *  第二層 object 靠 delivery 找是否需要 [運費]
 *  realShipAmount() @return {object}
 *  export format: realShipAmount { amount: 0, delivery: '1', isInstallment: 0, isShipFare: 0, realShipFare: 0 }
 *  */
const realShipAmount = ({ isInstantAmount }, { realShipAmounts, delivery }) =>
  compose(
    defaultTo(defaultShipAmount),
    find(propEq('delivery', delivery)),
    defaultTo([]),
    nth(isInstantAmount)
  )(realShipAmounts);

/** 本次實際運費 */
const realShipFare = (state, { realShipAmount: { realShipFare } }) => realShipFare;

/** 此為使用 [運費 + 實付金額] API 計算給的 amount(含運) */
const realAmount = (state, { realShipAmount: { amount } }) => amount;

/** 傳給 computed 用 */
const getterDiscountList = ({ getDiscountList }) => getDiscountList;

/** 本次是否可分期 */
/** 這裡的改變，會觸發 real-amount.vue 的 watch 改變 */
/** 這是很不得已的 anti-pattern，不建議經常使用
 * @return {Number} 0, 1
 * */
const isInstallment = (state, { realShipAmount: { isInstallment } }) => isInstallment;

/** 產生 [取得運費 + 實付金額] API 所需要的參數 */
const createParam = (totalAmount, deductAmount, cartType, isCycle, isVTP) => ({
  amount: `${totalAmount}`,
  deductAmount,
  type: cartType,
  isCycle,
  isVTP,
});

/** 存入 [運費]、[應付金額]、[免運費門檻] */
const saveShipFare = (commit) => (res) => {
  const { shipFare, amounts, shipFareFreeLimit } = res.data.data;
  const limit = renderState('AmountDetail', 'installmentLimit');
  amounts.forEach((amount) =>
    Object.keys(amount).forEach((item) => (amount[item].isInstallment = amount[item].amount > limit ? 1 : 0))
  );
  // 如果呼叫運費API的 vCash || hamiPoint >= 應付金額 || 有週期購商品則寫入該車實際運費供計算
  commit('setShipFare', shipFare);
  commit('setAmounts', amounts);
  commit('setShipFareFreeLimit', shipFareFreeLimit);
};

/** 儲存 [所有分期資料]、[分期門檻] */
const saveInstallments = (res) => {
  const { installments } = res.data.data;
  if (installments !== undefined && installments.length > 0) {
    commitMutation('InstallmentInfo', 'installments', installments);
    commitMutation('PaymentInfo', 'installment', prop('enable', paymentStatusEnum));
  } else {
    // 若分期陣列為空時 disable 分期選項狀態
    dispatchAction('PaymentInfo', 'disableInstallment');
  }
};

/** 存入 [福利金] ; 如呼叫失敗，即寫入 0 隱藏福利金組件 */
const saveVCash =
  (commit) =>
  ({ data }) => {
    if (data.code === 0) {
      return;
    }
    const { vCash } = data.data;
    const VCash = parseInt(vCash, 10);
    // for call ship-fare API
    commit('setVCash', VCash);
    if (VCash === 0) {
      return;
    }
    const getVCashObj = {
      discountName: 'VCash',
      title: '使用福利金折抵；福利金可用餘額：',
      deductTxt: '福利金折抵：',
      note: '',
      mobileDiscountName: '福利金',
      mobileDiscountTxt: '福利金',
      deduct: 0,
      isUsed: true,
    };
    commit('setVCashObj', getVCashObj);
  };

/** 例外情況:導去 cartMain 頁 */
const catchResponse = (error) => {
  console.log(error);
  const redirectPath = isMobile() ? '/m/cart/main' : '/cart/main';
  const cartType = renderState('AmountDetail', 'cartType');
  return router.replace({ path: redirectPath, query: { type: cartType } });
};

/** [取得福利金] API
 * from 神腦生活: 先 [取得福利金] API，再 [運費 + 實付金額] API
 * 未登入者直接 call [運費 + 實付金額] API
 * 如呼叫失敗，在 catch 直接呼叫[運費 + 實付金額] API */
const getVCash = ({ commit, dispatch }) => {
  const isNotLoginUser = store.state.CartDetail.isLogin === false;
  if (isNotLoginUser) {
    dispatch('getAmount');
    return;
  }
  fetchVCash()
    .then(saveVCash(commit))
    .then(() => dispatch('getAmount'));
};

/** 產生 [取得信用卡分期] API 所需要的參數
 * {k: v} -> {k: v}
 * vCash = vCash + hamipoint + edenred
 *  @return {object}
 * */
const createInstallmentParam = applySpec({
  deductAmount: () =>
    renderState('AmountDetail', 'deductedVCashState') +
    renderState('AmountDetail', 'deductedHamiPointState') +
    renderState('AmountDetail', 'deductedEdenredState'),
  shipFare: getGetter('InstallmentInfo', 'realShipFare'),
  checksum: getGetter('InstallmentInfo', 'checksum'),
  groups: () => JSON.stringify(renderState('InstallmentInfo', 'categories')),
});

/** 計算折扣核心 code */
const calculateDiscountListFn = (
  { commit, state: { isCycle, getDiscountList }, getters: { realTotalAmount } },
  payload
) => {
  // 如何區分 initCheck & userCheck?
  const userCheck = payload.discountName;
  // 可折抵總金額 = 週期購 ? (總金額 - 1) : 總金額
  let deductAmount = isCycle ? realTotalAmount - 1 : realTotalAmount;
  const mapDiscountList = getDiscountList.map((cloneItem) => {
    // for Vuex 規則; 需 copy 一份 obj 才能操作 state
    const item = Object.assign({}, cloneItem);
    const isUserCheckThisDiscount = item.discountName === userCheck;
    // 如訂單金額或折扣金額為 0 時
    if (deductAmount <= 0 || item.total <= 0) {
      // 如 USER 選取該項且該項當下為未使用時
      if (isUserCheckThisDiscount && !item.isUsed) {
        alert('金額已被全額折抵，請取消其他折抵方式');
      }
      item.isUsed = false;
    } else if (isUserCheckThisDiscount) {
      item.isUsed = !item.isUsed;
    }
    // method 來的才要 update DOM
    const thisCheckboxDOM = document.getElementById(`check${item.discountName}`) || null;
    if (thisCheckboxDOM !== null) {
      thisCheckboxDOM.checked = item.isUsed;
    }
    const deduct = item.isUsed ? Math.min(item.total, deductAmount) : 0;
    if (item.deduct !== deduct) {
      commit(`setDeducted${item.discountName}State`, deduct);
      item.deduct = deduct;
    }
    deductAmount -= deduct;
    return item;
  });
  commit('setGetDiscountList', mapDiscountList);
};

/** For 第一次進結帳頁時依條件計算好的實扣折扣金額
 * 因福利金優先度最高，所以需先計算全折金額，再丟迴圈裡與其他折扣計算 */
const onLoadDiscountListFn = ({
  commit,
  dispatch,
  state: { isCycle, vCash, VCashObj, hamipointObj, edenredObj },
  getters: { realTotalAmount },
}) => {
  const thisRealTotalAmount = isCycle ? realTotalAmount - 1 : realTotalAmount;
  // check if 有福利金，則先在此計算 realVCash, set 進 VCashObj
  if (vCash > 0) {
    // for 第一次進結帳頁時依條件計算好的實扣福利金金額
    const realVCash = Math.min(vCash, thisRealTotalAmount);
    const setVCashObj = Object.assign(VCashObj, { total: realVCash });
    commit('setVCashObj', setVCashObj);
  }
  const mapDiscountList = [VCashObj, hamipointObj, edenredObj];
  // filter empty object
  const filterDiscountList = mapDiscountList.filter((value) => JSON.stringify(value) !== '{}');
  if (filterDiscountList.length === 0) {
    return;
  }
  commit('setGetDiscountList', filterDiscountList);
  // 傳空物件以跳過 calculateDiscountListFn 裡 isUsed 那塊邏輯
  dispatch('calculateDiscountListFn', {});
};

/** [運費 + 實付金額] API
 * 因為 [運費 + 實付金額] API 的 [應付總金額] 不包含 [運費]
 * 所以不能使用 realTotalAmount
 * 這裡 call 分期 API 的條件是: 看 [checkOut API] payments[] 裡是否有分期選項 */
const getAmount = ({
  commit,
  state: { totalAmount, vCash, isCycle, cartType, isVTP, hamiPoint },
  getters,
  rootGetters,
  dispatch,
}) => {
  const deductAmount = vCash + hamiPoint + edenredBalanceTotal();
  // 參數必需對應
  fetchShipFare(createParam(totalAmount, deductAmount, cartType, isCycle, isVTP))
    .then(saveShipFare(commit))
    .then(() => dispatch('onLoadDiscountListFn'))
    .then(() => {
      const installmentStatus = rootGetters['PaymentInfo/installmentStatus'];
      // 如果是不可分期 OR (福利金 + hamipoint + 宜睿) > 0 OR 零元訂單 則不 call getBest，由 watch 監控呼叫
      if (
        installmentStatus === undefined ||
        vCash + hamiPoint + edenredBalanceTotal() > 0 ||
        getters.realTotalAmount === 0
      ) {
        return false;
      }
      // 有分期選項即 call getBest
      return fetchInstallment(createInstallmentParam());
    })
    .then((data) => (data === false ? data : saveInstallments(data))) // 有呼叫分期API即存至 saveInstallments() ; 無則 false
    .catch((error) => catchResponse(error));
};

export default {
  namespaced: true,
  state: Object.assign({}, defaultState),
  getters: {
    realTotalAmount,
    realInstantAmount,
    realShipAmounts,
    realShipAmount,
    realShipFare,
    realAmount,
    isInstallment,
    /** 配送方式 */
    delivery: getState('DeliveryInfo', 'delivery'),
    getterDiscountList,
  },
  mutations: {
    /** 修改 [小計] */
    setSubTotal: makeMutation('subTotal'),
    /** 修改 [神腦幣] */
    setBonusPoint: makeMutation('bonusPoint'),
    /** 修改由 [設定購物車結帳] API 取得 [活動折抵/整車折抵金額] */
    setCartDiscount: makeMutation('cartDiscount'),
    /** 修改 [應付總金額] 不含 [運費] */
    setTotalAmount: makeMutation('totalAmount'),
    setVCash: makeMutation('vCash'),
    setHamiPoint: makeMutation('hamiPoint'),
    /** 修改 由 [取得運費 + 應付金額] API 取得 [運費]  */
    setShipFare: makeMutation('shipFare'),
    /** 修改 由 [取得運費 + 應付金額] API 取得 [應付金額] */
    setAmounts: makeMutation('amounts'),
    /** 修改 由 [取得運費 + 應付金額] API 取得 [免運費門檻] */
    setShipFareFreeLimit: makeMutation('shipFareFreeLimit'),
    /** 修改 由 [取得運費 + 應付金額] API 取得 [分期門檻] */
    setInstallmentLimit: makeMutation('installmentLimit'),
    /** 修改 由 [設定購物車結帳] API 取得 [明細總金額] */
    setTotalPrice: makeMutation('totalPrice'),
    /** 修改 由 [設定購物車結帳] API 取得 [優惠券折抵金額] */
    setItemCoupon: makeMutation('itemCoupon'),
    /** 修改 由 [設定購物車結帳] API 取得 [應付金額] */
    setCartAmount: makeMutation('cartAmount'),
    /** 修改 由 [設定購物車結帳] API 取得 [折抵神腦幣] */
    setUsedPoint: makeMutation('usedPoint'),
    /** 修改 由 [設定購物車結帳] API 取得 [神腦幣比率] */
    setPointRate: makeMutation('pointRate'),
    /** 修改 由 [設定購物車結帳] API 取得 [是否整車電子票券] */
    setIsVTP: makeMutation('isVTP'),
    /** 修改 由 [設定購物車結帳] API 取得 [明細異動 checksum] */
    setChecksum: makeMutation('checksum'),
    /** 修改 由 [設定購物車結帳] API 取得 [CSRF] */
    setCsrf: makeMutation('csrf'),
    /** 修改 由 [設定購物車結帳] API 取得 [有無週期購商品] */
    setIsCycle: makeMutation('isCycle'),
    setCartType: makeMutation('cartType'),
    setIsInstantAmount: makeMutation('isInstantAmount'),
    setDeductedVCashState: makeMutation('deductedVCashState'),
    setDeductedHamiPointState: makeMutation('deductedHamiPointState'),
    setDeductedEdenredState: makeMutation('deductedEdenredState'),
    setHamipointObj: makeMutation('hamipointObj'),
    setVCashObj: makeMutation('VCashObj'),
    setEdenredObj: makeMutation('edenredObj'),
    setGetDiscountList: makeMutation('getDiscountList'),
    /** 重置 store */
    RESET_STATE(state) {
      Object.keys(state).forEach((prop) => {
        state[prop] = defaultState[prop];
      });
    },
  },
  actions: {
    getVCash,
    getAmount,
    onLoadDiscountListFn,
    calculateDiscountListFn,
  },
};
