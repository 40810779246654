import { getMutation, makeMutation, dispatchAction, commitMutation } from '@/helpers/vuex';
import fetchQuickSettle from '@/api/step1/quick-settle';
import { autoMap } from '@/helpers/object';
import { getCardType } from '@/components/desktop/step1/payment-method/common/child/child/constant/creditcard-demo.constant';

const defaultState = {
  /** 收件人 */
  name: '',
  /** 手機 */
  mobilePhone: '',
  /** 市話區碼 */
  localPhoneArea: '',
  /** 市話 */
  localPhone: '',
  /** 市話分機 */
  localPhoneExt: '',
  /** Email */
  email: '',
  /** 郵遞區號 */
  addressZip: '',
  /** 收件地址之市 ID */
  addressCityId: '',
  /** 收件地址之市 */
  addressCity: '',
  /** 收件地址之鄉鎮區 ID */
  addressTownId: '',
  /** 收件地址之鄉鎮區 */
  addressTown: '',
  /** 收件地址 */
  address: '',
  /** 信用卡號碼 */
  cardNo: '',
  /** 信用卡卡別 (美國運通不可折抵及分期) */
  cardType: '',
  /** 信用卡有效期限-月 */
  cardExpiredMonth: '',
  /** 信用卡有效期限-年 */
  cardExpiredYear: '',
  /** 快速刷卡卡號 */
  quickCardNo: '',
  /** 快速刷卡有效月 */
  quickExpiredMonth: '',
  /** 快速刷卡有效年 */
  quickExpiredYear: '',
  /** 快速刷卡銀行代碼 */
  quickIssuer: 0,
  /** 快速刷卡代碼 */
  quickAuthId: 0,
  /** 信用卡分期期數 */
  period: 0,
  /** 信用卡每期金額 */
  periodPay: 0,
  /** 結帳用 cvc */
  cvc: '',
  /** CVC 是否有修改 */
  toggleCvc: true,
  /** 記住信用卡資訊下次使用 */
  cardKeep: false,
  /** 用來判斷是否已呼叫過此 API
   * 1=OK, 2=未登入, 3=查無持卡人, 0=失敗
   */
  code: '',
  /** 由 [設定購物車結帳] API 取得 [是否為週期購] */
  isCycle: 0,
  /** 由 [設定購物車結帳] API 取得 [週期購的最大週期] */
  validThru: 0,
  cityId: '',
  townId: '',
};

/** 直接顯示 [信用卡輸入] */
const showCreditCardInput = getMutation('PaymentInfo', 'isShowRecord', false);

/** 儲存 [快速刷卡] */
const saveQuickSettle =
  (commit) =>
  ({ data }) =>
    commit('setQuickSettle', data);

/** 寫入 [持卡人資訊] */
const setCardInfo = (state, creditCardInfo) => {
  autoMap(creditCardInfo, state, {
    username: 'name',
    mobile: 'mobilePhone',
    cityName: 'addressCity',
    townName: 'addressTown',
    userPhoneNo: 'localPhone',
    userPhoneCode: 'localPhoneArea',
    userPhoneExt: 'localPhoneExt',
  });
};

/** 寫入 [快速刷卡] */
const setQuickSettle = (state, { data: { quickSettle } }) => {
  autoMap(quickSettle, state, {
    cardNo: 'quickCardNo',
    expireMonth: 'quickExpiredMonth',
    expireYear: 'quickExpiredYear',
    issuer: 'quickIssuer',
  });
};

/** [取得快速刷卡資訊] API */
/** 若 API 呼叫失敗，直接顯示 [信用卡輸入] */
const getQuickSettle = ({ commit }) =>
  fetchQuickSettle()
    .then((res) => {
      if (res.data.code === 1 && res.data.data.quickSettle.cardNo !== '') {
        // 更新信用卡卡別
        const updateCardType = getCardType(res.data.data.quickSettle.cardNo.substr(0, 4) || '') || '';
        commit('setCardType', updateCardType);
        commit('setCardKeep', true);
        commitMutation('PaymentInfo', 'isShowRecord', true);
        // 更新 payments
        dispatchAction('PaymentInfo', 'AmexUpdatePaymentType');
      }
      return res;
    })
    .then(saveQuickSettle(commit))
    .catch(showCreditCardInput);

// 目標 : 判斷是否為週期購商品及驗證卡片有效期限
// 實作 : 檢查如果到期日小於週期購最大日期，則 alert 並滑到輸入卡號那重填
// validThru 如為當年當月值表示為非週期購車
const isValidateExpired = ({ validThru, cardExpiredMonth, quickExpiredMonth, cardExpiredYear, quickExpiredYear }) => {
  const realCardMonth = (cardExpiredMonth || quickExpiredMonth).toString();
  const realCardYear = (cardExpiredYear || quickExpiredYear).toString();
  const cardPeriod = realCardYear + realCardMonth;
  return parseInt(cardPeriod, 10) - validThru < 0;
};

export default {
  namespaced: true,
  state: Object.assign({}, defaultState),
  mutations: {
    /** 修改 [收件人] */
    setName: makeMutation('name'),
    /** 修改 [手機] */
    setMobilePhone: makeMutation('mobilePhone'),
    /** 修改 [市話區碼] */
    setLocalPhoneArea: makeMutation('localPhoneArea'),
    /** 修改 [市話] */
    setLocalPhone: makeMutation('localPhone'),
    /** 修改 [市話分機] */
    setLocalPhoneExt: makeMutation('localPhoneExt'),
    /** 修改 [Email] */
    setEmail: makeMutation('email'),
    /** 修改 [郵遞區號] */
    setAddressZip: makeMutation('addressZip'),
    /** 修改 [收件地址之市 ID] */
    setAddressCityId: makeMutation('addressCityId'),
    /** 修改 [收件地址之市] */
    setAddressCity: makeMutation('addressCity'),
    /** 修改 [收件地址之鄉鎮區 ID] */
    setAddressTownId: makeMutation('addressTownId'),
    /** 修改 [收件地址之鄉鎮區] */
    setAddressTown: makeMutation('addressTown'),
    /** 修改 [收件地址] */
    setAddress: makeMutation('address'),
    /** 修改 [信用卡號碼] */
    setCardNo: makeMutation('cardNo'),
    /** 修改 [信用卡卡別] */
    setCardType: makeMutation('cardType'),
    /** 修改 [信用卡有效期限-月] */
    setCardExpiredMonth: makeMutation('cardExpiredMonth'),
    /** 修改 [信用卡有效期限-年] */
    setCardExpiredYear: makeMutation('cardExpiredYear'),
    setCardInfo,
    setQuickSettle,
    /** 修改 [信用卡分期期數] */
    setPeriod: makeMutation('period'),
    /** 修改 [信用卡每期金額] */
    setPeriodPay: makeMutation('periodPay'),
    /** 修改 [cvc] */
    setCvc: makeMutation('cvc'),
    /** 修改 [CVC 是否有修改] */
    setToggleCvc: makeMutation('toggleCvc'),
    /** 修改 [記住信用卡資訊下次使用] */
    setCardKeep: makeMutation('cardKeep'),
    /** 修改 [res.code] */
    setCode: makeMutation('code'),
    /** 修改 由 [設定購物車結帳] API 取得 [是否為週期購] */
    setIsCycle: makeMutation('isCycle'),
    /** 修改 由 [設定購物車結帳] API 取得 [週期購的最大週期] */
    setValidThru: makeMutation('validThru'),
    /** 修改 快速刷卡卡號 */
    setQuickCardNo: makeMutation('quickCardNo'),
    /** 修改 快速刷卡有效月 */
    setQuickExpiredMonth: makeMutation('quickExpiredMonth'),
    /** 修改 快速刷卡有效年 */
    setQuickExpiredYear: makeMutation('quickExpiredYear'),
    /** 修改 快速刷卡銀行代碼 */
    setQuickIssuer: makeMutation('quickIssuer'),
    setCityId: makeMutation('cityId'),
    setTownId: makeMutation('townId'),
  },
  getters: {
    isValidateExpired,
  },
  actions: {
    getQuickSettle,
  },
};
