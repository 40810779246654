<template>
  <div>
    <HeaderComponent></HeaderComponent>
    <router-view :isEmpty="isEmpty" :isLogin="this.isLogin" :cartType="this.getUrlCartType"></router-view>
    <scrollTopBtn></scrollTopBtn>
    <FooterComponent></FooterComponent>
  </div>
</template>

<script>
import callGetCartCount from '@/mixins/call-get-cart-count';
import HeaderComponent from '@/components/Utils/header.vue';
import FooterComponent from '@/components/Utils/footer.vue';
import scrollTopBtn from '@/components/Utils/scrollTopBtn.vue';

export default {
  name: 'cart-main',
  components: {
    HeaderComponent,
    FooterComponent,
    scrollTopBtn,
  },
  props: {
    isMobile: Boolean,
  },
  mixins: [callGetCartCount],
};
</script>
