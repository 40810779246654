import { makeMutation } from '@/helpers/vuex';

const defaultState = {
  /** 由 [設定購物車結帳] API 取得 [是否顯示收件人員工工號] */
  isEmployee: '',
  /** 由 [設定購物車結帳] API 取得 [收件人員工工號] */
  employeeId: '',
  /** 由 [設定購物車結帳] API 取得 [收件人姓名] */
  name: '',
  /** 由 [設定購物車結帳] API 取得 [收件人手機號碼] */
  mobilePhone: '',
  /** 由 [設定購物車結帳] API 取得 [收件人市話號碼] */
  localPhoneArea: '',
  /** 由 [設定購物車結帳] API 取得 [收件人市話區號] */
  localPhone: '',
  /** 由 [設定購物車結帳] API 取得 [收件人市話分機] */
  localPhoneExt: '',
  /** 由 [設定購物車結帳] API 取得 [是否顯示收件人電子信箱] */
  email: '',
  /** 由 [設定購物車結帳] API 取得 [收件人郵遞區號] */
  addressZip: '',
  /** 收件地址之市 ID */
  addressCityId: '',
  /** 由 [設定購物車結帳] API 取得 [收件人居住城市] */
  addressCity: '',
  /** 收件地址之鄉鎮區 ID */
  addressTownId: '',
  /** 由 [設定購物車結帳] API 取得 [收件人居住區域] */
  addressTown: '',
  /** 由 [設定購物車結帳] API 取得 [收件人居住地址] */
  address: '',
  /** 取貨門市之市 */
  senaoCity: '',
  /** 取貨門市之鄉鎮區 */
  senaoTown: '',
  /** 由 [設定購物車結帳] API 取得 [收件人取貨門市名稱] */
  senaoStore: '',
  /** 由 [設定購物車結帳] API 取得 [收件人取貨門市代碼] */
  senaoStoreId: '',
  /** 從 [city-town-stores] Vuex 寫入後順便存於此供 store-info 畫面顯示 */
  senaoStoreAddress: '',
  /** 從 [city-town-stores] Vuex 寫入後順便存於此供 store-info 畫面顯示 */
  typeName: '',
  /** 從 [city-town-stores] Vuex 寫入後順便存於此供 store-info 畫面顯示 */
  tel: '',
  senaoStoreZipcode: '',
  /** 是否加入收件人記事本 */
  isSave: null,
  /** 是否有選取過門市並去過 step2 */
  isSaveReceipt: false,
  /** user 確定選取的門市資料 */
  selectStore: [
    {
      storeName: '請選擇門市',
    },
  ],
  /** for map 頁暫時顯示資訊用 */
  selectStoreForMap: [],
};

const getterStoreName = ({ senaoStore }) => senaoStore;

export default {
  namespaced: true,
  state: Object.assign({}, defaultState),
  mutations: {
    setIsEmployee: makeMutation('isEmployee'),
    setEmployeeId: makeMutation('employeeId'),
    setName: makeMutation('name'),
    setMobilePhone: makeMutation('mobilePhone'),
    setLocalPhoneArea: makeMutation('localPhoneArea'),
    setLocalPhone: makeMutation('localPhone'),
    setLocalPhoneExt: makeMutation('localPhoneExt'),
    setEmail: makeMutation('email'),
    setAddressZip: makeMutation('addressZip'),
    setAddressCityId: makeMutation('addressCityId'),
    setAddressCity: makeMutation('addressCity'),
    setAddressTownId: makeMutation('addressTownId'),
    setAddressTown: makeMutation('addressTown'),
    setAddress: makeMutation('address'),
    setSenaoCity: makeMutation('senaoCity'),
    setSenaoTown: makeMutation('senaoTown'),
    setSenaoStore: makeMutation('senaoStore'),
    setSenaoStoreId: makeMutation('senaoStoreId'),
    setSenaoStoreAddress: makeMutation('senaoStoreAddress'),
    setSenaoStoreZipcode: makeMutation('senaoStoreZipcode'),
    setIsSave: makeMutation('isSave'),
    setIsSaveReceipt: makeMutation('isSaveReceipt'),
    setTypeName: makeMutation('typeName'),
    setTel: makeMutation('tel'),
    setSelectStore: makeMutation('selectStore'),
    setSelectStoreForMap: makeMutation('selectStoreForMap'),
    /** 重置 store */
    RESET_STATE(state) {
      Object.keys(state).forEach((prop) => {
        state[prop] = defaultState[prop];
      });
    },
  },
  getters: {
    getterStoreName,
  },
};
