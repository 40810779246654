import { makeMutation } from '@/helpers/vuex';

const setIsUserMember = makeMutation('isUserMember');
const setIsSubmitBtnDisabled = makeMutation('isSubmitBtnDisabled');
const setIsDisabledCheckedAgreement = makeMutation('isDisabledCheckedAgreement');
const setValidateErrorMsg = makeMutation('validateErrorMsg');
const setStep = makeMutation('step');
const setAuthCode = makeMutation('authCode');

const defaultState = {
  isUserMember: false,
  isSubmitBtnDisabled: false,
  isDisabledCheckedAgreement: false,
  validateErrorMsg: '',
  step: 'Step1',
  authCode: '',
};

export default {
  namespaced: true,
  state: Object.assign({}, defaultState),
  mutations: {
    setIsUserMember,
    setIsSubmitBtnDisabled,
    setIsDisabledCheckedAgreement,
    setValidateErrorMsg,
    setStep,
    setAuthCode,
    /** 重置 store */
    RESET_STATE(state) {
      Object.keys(defaultState).forEach((prop) => {
        state[prop] = defaultState[prop];
      });
    },
  },
};
