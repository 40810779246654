import Vue from 'vue';
import Vuex from 'vuex';
// import { mutationLog } from '@/helpers/storePlugin';
import AddressModalInfo from '@/store/modules/step1/address-modal-info';
import AmountDetail from '@/store/modules/step1/amount-detail';
import CardInfo from '@/store/modules/step1/card-info';
import Cart from '@/store/modules/cart/cart';
import CartDetail from '@/store/modules/step1/cart-detail';
import CityTownStores from '@/store/modules/step1/city-town-stores';
import CityTownTaiwan from '@/store/modules/step1/city-town-taiwan';
import DeliveryInfo from '@/store/modules/step1/delivery-info';
import DynamicComponents from '@/store/modules/step1/dynamic-components';
import InstallmentInfo from '@/store/modules/step1/installment-info';
import InvoiceInfo from '@/store/modules/step1/invoice-info';
import InvoiceModalInfo from '@/store/modules/step1/invoice-modal-info';
import Loading from '@/store/modules/loading';
import Login from '@/store/modules/login/login';
import Marquee from '@/store/modules/marquee';
import MobileCartStep2 from '@/store/modules/mobile/cart/step2';
import PaymentInfo from '@/store/modules/step1/payment-info';
import RecipientInfo from '@/store/modules/step1/recipient-info';
import Step1Component from '@/store/modules/step1/step1-component-enum';
import StoreInfo from '@/store/modules/step1/store-info';
import OrderInfo from '@/store/modules/step1/order-info';
import OrderFinishInfo from '@/store/modules/order-finish-info';
import ZingalaInfo from '@/store/modules/step1/zingala-info';
import RegisterSenaoMembership from '@/store/modules/register-senao-membership';
import Modal from '@/store/modules/modal';

Vue.use(Vuex);

/** vuex 擴充外掛 */
// const vuexPlugins = process.env.NODE_ENV === 'development' ? [mutationLog] : [];
export default new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  /** 註冊你自己的 module */
  modules: {
    Loading,
    Login,
    Cart,
    CityTownTaiwan,
    CityTownStores,
    CartDetail,
    AmountDetail,
    RecipientInfo,
    InvoiceInfo,
    CardInfo,
    PaymentInfo,
    Step1Component,
    DeliveryInfo,
    MobileCartStep2,
    DynamicComponents,
    InstallmentInfo,
    AddressModalInfo,
    InvoiceModalInfo,
    Marquee,
    StoreInfo,
    OrderInfo,
    OrderFinishInfo,
    RegisterSenaoMembership,
    ZingalaInfo,
    Modal,
  },
  // plugins: vuexPlugins,
});
