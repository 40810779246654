import { makeMutation } from '@/helpers/vuex';

/** 修改 [所有配送方式] */
const setDeliveries = makeMutation('deliveries');

/** 修改 [所選擇配送方式] */
const setDelivery = makeMutation('delivery');

export default {
  namespaced: true,
  state: {
    /** 由 [取得購物車明細] API 取得 [所有配送方式] */
    deliveries: [],
    /** user 選擇的配送方式 or 電子票券預設為 '0'，for checkOut API 判斷用 */
    delivery: '',
  },
  mutations: {
    setDeliveries,
    setDelivery,
  },
};
