import { prop, compose, defaultTo, nth } from 'ramda';
import { makeMutation, renderGetter } from '@/helpers/vuex';
import { isFetchZingalaEnum } from '@/constant/payment-info';
import { fetchZingala } from '@/api/step1/zingala-pay-later';

const index = compose(defaultTo(0), prop('installmentIndex'));

/** 目前所選擇分期 */
const zingalaUserSelectPeriod = ({ installments }, { index }) => compose(prop('period'), nth(index))(installments);

/** 目前所選分期之每月金額 */
const zingalaUserSelectPerMoney = ({ installments }, { index }) => compose(prop('perMoney'), nth(index))(installments);

const saveZingalaInstallmentsInfo =
  ({ commit }) =>
  (res) => {
    const { installments } = res.data.data;
    const hasValidInstallments = installments?.length > 0;
    if (hasValidInstallments) {
      const realAmount = renderGetter('AmountDetail', 'realInstantAmount');
      installments.sort((a, b) => a.period - b.period);
      const calculateInstallments = installments.map((item) => {
        item.totalAmount = Math.round(realAmount + realAmount * (item.rate / 100));
        item.perMoney = Math.round(item.totalAmount / item.period);
        return item;
      });
      commit('setInstallments', calculateInstallments);
      commit('setFetchZingalaState', isFetchZingalaEnum.hasFetch);
    }
  };

const setInstallments = makeMutation('installments');

const setInstallmentIndex = makeMutation('installmentIndex');

const setFetchZingalaState = makeMutation('fetchZingalaState');

const setIsCheckZingalaTerms = makeMutation('isCheckZingalaTerms');

export const actions = {
  getZingalaPay({ commit }) {
    return fetchZingala().then((res) => saveZingalaInstallmentsInfo({ commit })(res));
  },
};

export default {
  namespaced: true,
  state: {
    /** 所有分期資料 */
    installments: [
      {
        period: 0,
        rate: 0,
        totalAmount: 0,
        perMoney: 0,
      },
    ],
    fetchZingalaState: 0,
    /** 選擇分期 index */
    installmentIndex: 0,
    isCheckZingalaTerms: false,
  },
  getters: {
    index,
    zingalaUserSelectPeriod,
    zingalaUserSelectPerMoney,
  },
  mutations: {
    setInstallments,
    setFetchZingalaState,
    setInstallmentIndex,
    setIsCheckZingalaTerms,
  },
  actions,
};
