import axios from 'axios';
import { toKey } from '@/helpers/promise';

const API_PATH = `${process.env.VUE_APP_FRONT_API}${process.env.VUE_APP_BEST_INSTALLMENT}`;

/** 取得信用卡分期 */
export default (() => {
  const cache = {};
  return (args) => {
    const key = toKey(args);
    if (cache[key]) return new Promise((resolve) => resolve(cache[key]));

    const formData = new FormData();
    Object.keys(args).forEach((key) => formData.append(key, args[key]));
    return axios.post(API_PATH, formData).then((res) => (cache[key] = res));
  };
})();
