import { commitMutations, renderGetter, renderState } from '@/helpers/vuex';

/** Maps API is loaded only on demand */
export const loadGoogleMapsAPI = new Promise((resolve) => {
  window.GoogleMapsInit = resolve;
  const map = document.createElement('script');
  map.setAttribute(
    'src',
    `https://maps.googleapis.com/maps/api/js?key=AIzaSyDYeg3Ln5OgLA7bM53EIWp_glnjrB-rk78&callback=GoogleMapsInit&region=IN`
  );
  document.body.appendChild(map);
});

/**
 * 計算最近門市算式
 * @param {Number} lat1, lng1 - from APP or order address
 * @param {Number} lat2, lng2 - from serviceCenter data.cities[].districts[].stores[]
 * */
function getDistanceMatrix(lat1, lat2, lng1, lng2) {
  return Math.abs(lat1 - lat2) + Math.abs(lng1 - lng2);
}

/**
 * 寫入 vuex for 訂單的 postData
 * @param {Object} source
 * @param {Number} cityIndex
 * @param {Number} townIndex
 * @param {Number} storeIndex
 * used: fetchStoreInfo、hasCoordinateFlag、hasStoreInfoFlag、geocodeAddress
 * */
export const storeInfoSaveForPost = (source, cityIndex, townIndex, storeIndex) => {
  const { storeId, storeName, county, district, typeName, tel, fullAddress, zipcode } = source;
  const storeInfo = [];
  storeInfo.push(source);
  commitMutations('RecipientInfo', {
    senaoStoreId: storeId,
    selectStore: storeInfo,
    senaoStore: storeName,
    senaoCity: county,
    senaoTown: district,
    typeName,
    tel,
    senaoStoreAddress: fullAddress,
    senaoStoreZipcode: zipcode,
  });
  commitMutations('CityTownStores', {
    cityIndex,
    townIndex: townIndex + 1,
    storeIndex: storeIndex + 1,
    isCalledStoreAPI: true,
  });
};

/**
 * 從 serviceCenter API 裡找出符合的 store
 * @param {String} storeId
 * @param {String} city
 * @param {String} district
 * @param {String} storeName
 * */
export const getStoreFromObj = (storeId, city, district, storeName) => {
  const allStoreInfo = renderState('CityTownStores', 'allStoreInfo');
  const index = allStoreInfo.findIndex((x) => x.storeId === storeId);
  const cities = renderGetter('CityTownStores', 'cities');
  const cityIndex = cities.findIndex((x) => x.name === city);
  const townIndex = cities[cityIndex].districts.findIndex((x) => x.name === district);
  const storeIndex = cities[cityIndex].districts[townIndex].stores.findIndex((x) => x.storeName === storeName);
  return storeInfoSaveForPost(allStoreInfo[index], cityIndex, townIndex, storeIndex);
};

/**
 * @param {Number} lat1
 * @param {Number} lng1
 * */
export const getNearbyStoreFn = (lat1, lng1) => {
  const allStorePosition = renderState('CityTownStores', 'allStorePosition');
  const allStoreInfo = renderState('CityTownStores', 'allStoreInfo');
  // 存放目前位置與全台門市計算下的結果 @return Array
  const saveNearbyStore = [];
  for (let i = 0; i < allStorePosition.length; i++) {
    saveNearbyStore.push(getDistanceMatrix(lat1, allStorePosition[i][0], lng1, allStorePosition[i][1]));
  }
  // 找出陣列裡的最小值 > 查 index -> 回原資料裡用 index 回查印出資料
  const index = saveNearbyStore.indexOf(Math.min(...saveNearbyStore));
  // 找出該 store Info
  if (index > -1) {
    const { storeId, county, district, storeName } = allStoreInfo[index];
    getStoreFromObj(storeId, county, district, storeName);
  }
};

/** 呼叫 google map API 使用訂購人地址查詢座標 */
export const geocodeAddress = () => {
  const orderInfoAddress = renderState('OrderInfo', 'fullAddress');
  const geocoder = new google.maps.Geocoder();
  geocoder.geocode({ address: orderInfoAddress }, (results, status) => {
    if (status === 'OK') {
      if (results[0]) {
        const res = results[0].geometry.location;
        getNearbyStoreFn(res.lat(), res.lng());
      } else {
        // eslint-disable-next-line no-console
        console.log('No results found');
      }
    } else {
      // eslint-disable-next-line no-console
      console.log(`Geocode was not successful for the following reason: ${status}`);
    }
  });
};
