/** 還沒輸入信用卡卡號時，預設為灰色 */
export const initialCardType = { 'sn-card-unknown': false };
/** other (含 amex, discover, maestro, dankort 等其他卡別) */
export const defaultCardType = { 'sn-card-unknown': true };

/** 信用卡與 CSS 對照表 */
export const cardTypeLut = {
  Amex: { 'sn-card-identified sn-card-amex': true },
  JCB: { 'sn-card-identified sn-card-jcb': true },
  MasterCard: { 'sn-card-identified sn-card-mastercard': true },
  Visa: { 'sn-card-identified sn-card-visa': true },
};

/** 取得信用卡卡別 */
export const getCardType = (cardNumber) => {
  if (/^3[47]/.test(cardNumber)) return 'Amex';
  if (/^35/.test(cardNumber)) return 'JCB';
  if (/^(5[1-5]|677189)|^(222[1-9]|2[3-6]\d{2}|27[0-1]\d|2720)/.test(cardNumber)) return 'MasterCard';
  if (/^4/.test(cardNumber)) return 'Visa';
  return '';
};

/**
 * 卡號畫面格式化
 * @param {string} str 卡號
 * @param {string} type 卡別
 * @return {string} 卡號分段
 */
export const cardFormat = (str, type) => {
  switch (type) {
    case 'Amex': // 4 6 5
      return `${str.substr(0, 4)} ${str.substr(4, 6)} ${str.substr(10, 5)}`;
    default:
      // 4 4 4 4
      return `${str.substr(0, 4)} ${str.substr(4, 4)} ${str.substr(8, 4)} ${str.substr(12, 4)}`;
  }
};
