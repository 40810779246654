import { find, prop, propEq, pipe, equals } from 'ramda';
import { commitMutation, makeMutation, renderState } from '@/helpers/vuex';
import { setProp } from '@/helpers/object';
import { paymentEnum, paymentStatusEnum } from '@/constant/payment-info';

/** 根據 paymentEnum.creditCardInstallment 搜尋 */
const finder = pipe(prop('creditCardInstallment'), propEq('paymentType'));

/** 動態決定是否可使用 [信用卡分期付款]
 *  使用於有福利金的情境
 *  找出分期並修改其 status {number} 0, 1, 2
 * @param state
 * @param payload
 * 目的是用來修改 payments 裡的分期的 status */
const setInstallment = (state, payload) => {
  const findInstallment = pipe(prop('payments'), find(finder(paymentEnum)));
  setProp('status', payload, findInstallment(state));
};

/** 目前的分期狀態
 * 在是否使用福利金全抵的情境中，回傳的值決定分期的狀態
 * @return {Number} undefined, 0, 1, 2
 * */
const installmentStatus = pipe(prop('payments'), find(finder(paymentEnum)), prop('status'));

const defaultState = {
  /** 由 [設定購物車結帳] API 取得 [可用付款方式] */
  /** 99: 信用卡紅利折抵, 100: 信用卡一次付款, 199: 信用卡分期付款 */
  /** 200: ATM 轉帳, 300: 神腦門市繳費, 400: LINE Pay 付款, 401 街口支付, 402: Pi錢包, 403: 台灣Pay, 405: 悠遊付, 500: ApplePay */
  /** 可用付款方式 */
  payments: [],
  /** 付款方式不設定預設值 */
  payment: 0,
  /** 記住 User 在 step2 時最後選的付款方式 Index */
  userPickPaymentIndex: 0,
  /** 是否顯示 [信用卡資料] */
  isShowRecord: true,
  /** 同意神腦代為處理發票及銷貨退貨證明 (預設要打勾) */
  agreeCheck: true,
  /** 由 [設定購物車結帳] API 取得 [付款人資訊是否唯讀] */
  isPayerReadOnly: false,
  applePayPaymentToken: '',
  /** [手機版] 行動支付方式 */
  mobilePayments: [],
  /** [手機版] 行動支付方式不設定預設值 */
  mobilePayment: 0,
};

export default {
  namespaced: true,
  state: Object.assign({}, defaultState),
  getters: {
    installmentStatus,
  },
  mutations: {
    /** 修改 [目前所選擇付款方式] */
    setPayment: makeMutation('payment'),
    /** 修改 [可用付款方式] */
    setPayments: makeMutation('payments'),
    setUserPickPaymentIndex: makeMutation('userPickPaymentIndex'),
    /** 修改 [是否顯示信用卡資料] */
    setIsShowRecord: makeMutation('isShowRecord'),
    /** 同意神腦代為處理發票及銷貨退貨證明 */
    setAgreeCheck: makeMutation('agreeCheck'),
    setInstallment,
    /** 修改 [付款人資訊是否唯讀] */
    setIsPayerReadOnly: makeMutation('isPayerReadOnly'),
    setApplePayPaymentToken: makeMutation('applePayPaymentToken'),
    setMobilePayments: makeMutation('mobilePayments'),
    setMobilePayment: makeMutation('mobilePayment'),
    /** 重置 store */
    RESET_STATE(state) {
      Object.keys(state).forEach((prop) => {
        state[prop] = defaultState[prop];
      });
    },
  },
  actions: {
    /** 美國運通卡不可折抵及分期 */
    AmexUpdatePaymentType({ commit, state }) {
      // console.log(commit);
      const cardType = renderState('CardInfo', 'cardType');
      const list = state.payments.map((x) => Object.assign({}, x));
      list.map((x) => {
        if ([99, 199].indexOf(x.paymentType) > -1 && cardType === 'Amex') {
          x.status = 2;
          // 若卡別為 Amex 且目前 payment 選擇是折抵或分期時強制選為一次付清
          if ([99, 199].indexOf(state.payment) > -1) {
            commit('setPayment', 100);
          }
          return x;
        }
        x.status = x.defaultStatus;
        return x;
      });
      commit('setPayments', list);
    },
    /** 進結帳頁 call getBest，如 res 無內容即更新分期的 status */
    disableInstallment({ commit, state }) {
      const list = state.payments
        .map((x) => Object.assign({}, x))
        .map((x) => {
          const isInstallment = equals(x.paymentType, paymentEnum.creditCardInstallment);
          if (isInstallment) {
            // 將分期的狀態設為disable
            setProp('status', prop('disable', paymentStatusEnum), x);
            // only for Mobile ; 非為預設值 0 表示 User 有選過付款方式
            if (state.userPickPaymentIndex !== 0) {
              return x;
            }
            // 為避免因切換使用部分福利金使分期狀態為 disable user 停留在分期 component,
            // 分期為 disable 時強制轉至信用卡一次付款
            commit('setPayment', paymentEnum.creditCardOnetime);
            commitMutation('DynamicComponents', 'paymentMethod', 'creditcard-onetime');
          }
          setProp('defaultStatus', prop('status', x), x);
          return x;
        });
      commit('setPayments', list);
    },
  },
};
