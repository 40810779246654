import { compose, find, propEq, propOr } from 'ramda';
import * as baseData from '../../node/json/step1/city-town-taiwan.json';

/** @const {Object} city town json */
export const baseCityTown = baseData.default;

const cityTown = baseCityTown.data.cities || [];

/** 產生是否外島的 filter function
 * ＠return {Boolean}
 * */
const isIslandInCities = (island) => {
  if (island) return () => true;
  return (x) => !x.districts.every((y) => y.isIsland === !island);
};

/** 產生是否外島的 filter function */
const isIslandInTowns = (island) => {
  if (island) return () => true;
  return (x) => x.isIsland === island;
};

/**
 * 縣市列表 (不含鄉鎮地區)
 * @param {Boolean} showIsland 顯示離島？ (default true)
 * @return {Array} city json array
 */
export const cities = (showIsland = true) => cityTown.filter(isIslandInCities(showIsland));

/**
 * 取得鄉鎮地區 by id
 * @param {String} cityId 縣市地區 id
 * @param {Boolean} showIsland 顯示離島？ (default true)
 * @return {Array} town json array
 */
export const towns = (cityId = '', showIsland = true) =>
  ((find((x) => x.id === cityId, cityTown) || {}).districts || []).filter(isIslandInTowns(showIsland));

/**
 * 取得鄉鎮地區 by name
 * @param {String} cityName 縣市地區名稱
 * @param {Boolean} showIsland 顯示離島？ (default true)
 * @return {Array} town json array
 */
export const townsByName = (cityName = '', showIsland = true) =>
  ((find((x) => x.name === cityName, cityTown) || {}).districts || []).filter(isIslandInTowns(showIsland));

/**
 * 取得鄉鎮地區 by index
 * @param {Number} index 縣市地區 index
 * @param {Boolean} showIsland 顯示離島？ (default true)
 * @return {Array} town json array
 */
export const townsByIndex = (index = 0, showIsland = true) =>
  ((cities(showIsland)[index] || []).districts || []).filter(isIslandInTowns(showIsland));

/** 由 [城市名稱] 查詢 [城市 ID] */
export const getCityIdByName = ({ cityName }) =>
  compose(propOr('', 'id'), find(propEq('name', cityName)), propOr('', 'cities'), () => baseCityTown.data)();

/**
 * cityId & townId 使用同一個 Fn(), 用 zip code 查。
 * @param {string} zipCode
 * */
export const getCityAndTownIdByZip = (zipCode) => {
  const { cities } = baseCityTown.data;
  const reducer = (acc, citiesObj, citiesIndex) => {
    citiesObj.districts.forEach((town) => {
      if (town.zip.indexOf(zipCode) > -1) {
        acc.cityId = cities[citiesIndex].id;
        acc.townId = town.id;
      }
    });
    return acc;
  };
  const initialObj = {
    cityId: '',
    townId: '',
  };
  return cities.reduce(reducer, initialObj);
};

/** 由 [鄉鎮區名稱] 查詢 [鄉鎮區 ID] */
export const getTownIdByName = ({ cityName, townName }) =>
  compose(
    propOr('', 'id'),
    find(propEq('name', townName)),
    propOr('', 'districts'),
    find(propEq('name', cityName)),
    propOr('', 'cities'),
    () => baseCityTown.data
  )();

/** 由 [鄉鎮區名稱] 查詢 [鄉鎮區 zip code] */
export const getZipCodeByCityTownName = ({ cityName, townName }) =>
  compose(
    propOr('', 'zip'),
    find(propEq('name', townName)),
    propOr('', 'districts'),
    find(propEq('name', cityName)),
    propOr('', 'cities'),
    () => baseCityTown.data
  )();

/** 由 [城市 ID] 查詢 [城市名稱] */
export const getCityNameById = ({ cityId }) =>
  compose(propOr('', 'name'), find(propEq('id', cityId)), propOr('', 'cities'), () => baseCityTown.data)();

/** 由 [鄉鎮區 ID] 查詢 [鄉鎮區名稱] */
export const getTownNameById = ({ cityId, townId }) =>
  compose(
    propOr('', 'name'),
    find(propEq('id', townId)),
    propOr('', 'districts'),
    find(propEq('id', cityId)),
    propOr('', 'cities'),
    () => baseCityTown.data
  )();

/**
 * 過濾地址欄位的特殊符號
 * @return {String}
 * @param {String} address
 */
export const regexpAddress = (address) => {
  const regularExpressionChars = "[`~!@#%$^&*()=|{}':;',\\[\\].<>/?~！@#￥……&*（）——|{}【】‘；：”“'。，、？]";
  const regularExpression = new RegExp(regularExpressionChars);
  return address.split(regularExpression).join('').replace(/\s/g, ''); /** @return {string} */
};
