import {
  getBonus,
  getCartDetail,
  getCoupons,
  getShipFare,
  updateProductQty,
  getBalance,
  fetchBalance,
} from '@/api/cart-main';
import getShippingFee from '@/api/step1/ship-fare';
import { fetchHamiPoint } from '@/api/hami-point';
import { commitMutation, commitMutations, dispatchAction, makeMutation, renderState } from '@/helpers/vuex';
import { isMobile, redirectHostname } from '@/helpers/is-from-app';
import { edenredBalanceTotal, getEdenredGroup } from '@/helpers/edenred';
import { createParameterArray, sendGA4sEvent } from '@/helpers/tracking/ga';
import { openModal, closeModal } from '@/helpers/modal';

/** 當API回未登入時的行為，use for hamipoint & 宜睿 */
export const redirectToLogin = () => {
  const redirectPath = isMobile() ? '/m/Member/Login?redirect=' : '/login?redirect=';
  const redirectPathPage = isMobile() ? '/m/cart/choice' : '/cart/main';
  window.location.href = `${process.env.VUE_APP_ONLINE}${redirectPath}${encodeURIComponent(
    process.env.VUE_APP_CART
  )}${redirectPathPage}`;
};
/** 取值與判斷與寫入 store */
const saveCartDetail = (res, dispatch) => {
  /** 錯誤處理 res.data.code
   * 0=Fail，cart/main reload
   * 1 & 無 description => 導去 step1
   * 1 & 有 description ( 通常是賣場已結束 ) => alert description
   * 2=未登入，導去登入頁
   * 3=黑名單，導回首頁
   * */
  switch (res.data.code) {
    case 3:
      alert(res.data.description);
      window.location.href = `${process.env.VUE_APP_ONLINE}`;
      return;
    case 1:
      break;
    case 0: {
      // 桌機版回 cartMain ; 手機版回 cartChoice
      const redirectPath = isMobile() ? '/m/cart/choice' : '/cart/main';
      window.location.href = `${redirectHostname()}${redirectPath}`;
      return;
    }
    default:
      window.location.reload();
      return;
  }
  if (res.data.description !== '') {
    alert(res.data.description);
  }
  // 如無產品明細，導去 cartMain 找車 */
  if (res.data.data.details.length === 0) {
    window.location.href = `${redirectHostname()}/m/cart/choice`;
    return;
  }
  const {
    isDirect,
    isDiscount,
    deliveries,
    isLogin,
    isLinePoints,
    isFromApp,
    details,
    pointMaxAmount,
    couponMarts,
    isVTP,
    isShowChtLoginMsg,
    rType,
  } = res.data.data;
  // finalPrice 最後顯示價格
  const allDetails = details.map((x, y) => ({
    ...x,
    cartIndex: y,
    couponId: '',
    couponDiscount: 0,
    couponDesc: '',
    finalPrice: x.oriPrice,
    selectEmpPrice: false, // 檢查 user 是否有選取員購價用 (for PC main 及 step1 都會用到)
    selectPricingPlan: false, // 檢查 user 是否有選取優惠方案用
  }));
  const itemParameters = createParameterArray(allDetails);
  const totalAmount = allDetails.reduce((sum, x) => sum + x.oriPrice * x.productQty, 0);
  const defaultParameterForGA = {
    currency: 'TWD',
    value: totalAmount,
    items: itemParameters,
  };
  sendGA4sEvent('view_cart', defaultParameterForGA);
  // 總可折商品的小計 ; isBonus: 可否折抵
  const discountSubTotal = allDetails.reduce(
    (sum, x) => (x.isBonus ? sum + (x.finalPrice * x.productQty - x.couponDiscount) : sum),
    0
  );
  const { delivery } = deliveries[0];
  commitMutation('DeliveryInfo', 'deliveries', deliveries);
  // 配送方法為單一的話才需預先寫入其運送方式 for checkOut 用
  if (deliveries.length < 2) {
    commitMutation('DeliveryInfo', 'delivery', delivery);
  }
  commitMutations('CartDetail', {
    isDirect,
    isVTP,
    cartDetail: allDetails,
    isDiscount,
    isLogin,
    isLinePoints,
    discountSubTotal,
    pointMaxAmount,
    couponMarts,
    isShowChtLoginMsg: Boolean(isShowChtLoginMsg),
    isRtype: rType,
  });
  // for PC 已登/未登
  commitMutation('Cart', 'isFromApp', isFromApp);
  if (isLogin) {
    dispatch('getCouponList');
    dispatch('getPoint');
    dispatch('getHamiPoint');
  }
  dispatch('queryEdenred');
  if (isMobile()) {
    dispatchAction('CartDetail', 'getMobileCartMain');
  }
  dispatch('getShip');
};

// 組合成手機版長相
const getMobileCartMain = ({ state: { cartDetail } }) => {
  let i = -1;
  const mobileCartList = [];
  // 為了解決增減數量時更新 cartIndex
  const resetDetail = cartDetail.map((x, y) => ({
    ...x,
    cartIndex: y,
  }));
  resetDetail.forEach((x) => {
    // 列表裡有主商品或顯示折扣合併為一組 array
    if (x.cartTag === '0' || x.cartTag === '1') {
      i++;
      mobileCartList[i] = new Array(x);
      return;
    }
    // 另外的為加購品與贈品，分別列出
    mobileCartList[i].push(x);
  });
  commitMutation('CartDetail', 'mobileCartList', mobileCartList);
  return mobileCartList;
};

const getCouponList = ({ state: { isLogin, couponMarts } }) => {
  const formData = new FormData();
  formData.append('martIdList', JSON.stringify(couponMarts));
  // 有登入且整車內有可折商品才能呼叫
  if (isLogin && couponMarts.length > 0) {
    getCoupons(formData).then((res) => {
      if (res.data.code !== 1) {
        // eslint-disable-next-line no-console
        console.log('取得優惠券失敗');
        return;
      }
      commitMutation('CartDetail', 'coupons', res.data.data.coupons);
    });
  }
};

/** POST to updateQuantity API */
const getProductQty = (context, data) => {
  // 取得參數 ex:martCode=456&productNo=abc&productQty=2
  const { item, newQty } = data;
  if (item.productQty === newQty) {
    return;
  }
  const formData = new FormData();
  formData.append('detailId', item.detailId);
  formData.append('martCode', item.martCode);
  formData.append('productNo', item.productNo);
  formData.append('productQty', newQty);
  updateProductQty(formData)
    .then((res) => {
      if (res.data.code !== 1) {
        alert(res.data.description);
        window.location.reload();
      }
    })
    .then(() => context.commit('setProductQty', data))
    .catch(() => window.location.reload());
};

/** 更新 cartDetail Store data */
const setProductQty = ({ cartDetail }, cartData) => {
  cartDetail[cartData.item.cartIndex].productQty = cartData.newQty;
  const { detailId } = cartDetail[cartData.item.cartIndex];
  cartDetail.forEach((x) => {
    // 主商品數量同步贈品
    if (x.parentId === detailId && x.cartTag === '3') {
      x.productQty = cartData.newQty * (x.perPieceQuantity || 1);
    }
  });
  isMobile() ? dispatchAction('CartDetail', 'getMobileCartMain') : false;
};

// 隨增減可折商品數量變更可用神腦幣及金額
const setCalculation = () => {
  const cartPoint = renderState('CartDetail', 'cartPoint');
  const { point, pointMaxExchangeRate, pointExchangeRate } = cartPoint;
  // 如果 user 有點數則預設勾選
  if (point > 0) {
    // 可折商品小計總和
    const discountSubTotal = renderState('CartDetail', 'discountSubTotal');
    // 最大可折點數 = 可折商品小計金額 * 神腦幣折抵%
    const maxPointDiscount = Math.floor((discountSubTotal * pointMaxExchangeRate) / 100);
    // 神腦幣最大可折金額
    const maxPointMoney = Math.floor(point / pointExchangeRate);
    // 格式化金額
    const formatMaxPoint = point.toLocaleString();
    // 使用最大紅利全折的情境，寫入最大值的神腦幣及金額
    if (maxPointDiscount >= maxPointMoney) {
      commitMutations('CartDetail', {
        point,
        formatPoint: formatMaxPoint,
        pointMoney: maxPointMoney,
      });
      return;
    }
    const defaultPoint = Math.floor(maxPointDiscount * pointExchangeRate);
    const pointMoney = Math.floor(defaultPoint / pointExchangeRate);
    const formatDefaultPoint = defaultPoint.toLocaleString();
    // 寫入紅利資料與換算過的紅利金額到 store
    commitMutations('CartDetail', {
      point: defaultPoint,
      formatPoint: formatDefaultPoint,
      pointMoney,
    });
  }
};

const getPoint = ({ commit, state: { isLogin, pointMaxAmount } }) => {
  // 有登入且有神腦幣可折抵最大金額才能呼叫查詢紅利 API
  if (isLogin && pointMaxAmount > 0) {
    getBonus(pointMaxAmount)
      .then((res) => {
        if (res.data.code !== 1) {
          // eslint-disable-next-line no-console
          console.log(`點數取得失敗`);
          return;
        }
        const isAutoCheck = res.data.data.cartPoint.point > 0;
        // 整包寫入
        commitMutations('CartDetail', {
          cartPoint: res.data.data.cartPoint,
          point: res.data.data.cartPoint.point,
          isCheckPoint: isAutoCheck, // 清除預設值 ; reset value to 0 預設不勾選
        });
      })
      .then(() => commit('setCalculation'));
  }
};

/** 存入 [HamiPoint] 及畫面判斷 */
const saveHamiPoint =
  (commit) =>
  ({ data }) => {
    const isNotAuthHamiPointCode = 3;
    if (data.code === isNotAuthHamiPointCode) {
      // show go to auth checkbox
      commit('setIsAuthHamiPoint', false);
      return;
    }
    if (data.code !== 1) {
      return;
    }
    // 已授權 ; show txt
    commit('setIsAuthHamiPoint', true);
    const { points } = data.data.point;
    const point = parseInt(points, 10);
    let setDiscountBlockContent = {};
    if (point > 0) {
      // for call ship-fare API
      commitMutation('AmountDetail', 'hamiPoint', point);
      setDiscountBlockContent = {
        discountName: 'HamiPoint',
        total: point,
        title: '使用Hami Point點數折抵；可用點數：',
        note: '1點折抵1元；確認結帳前，將會再次與Hami Point確認可用餘額點數。',
        deductTxt: 'Hami Point折抵：',
        mobileDiscountName: 'Hami Point點數',
        mobileDiscountTxt: '點數',
        isUsed: true,
      };
    }
    commit('setHamiPoint', point);
    commitMutation('AmountDetail', 'hamipointObj', setDiscountBlockContent);
  };

export const setEdenredBlockContent = () => {
  const setContent = {
    discountName: 'Edenred',
    total: edenredBalanceTotal(),
    title: '使用 Edenred 即享券折抵；可用餘額：',
    deductTxt: 'Edenred 即享券折抵：',
    note: '確認付款前，將會再次確認 Edenred 即享券系統可用餘額。',
    mobileDiscountName: 'Edenred 即享券',
    mobileDiscountTxt: '餘額',
    isUsed: true,
  };
  commitMutation('AmountDetail', 'edenredObj', setContent);
};

const saveQueryEdenredResponse =
  (commit) =>
  ({ data }) => {
    const fetchSuccess = 1;
    if (data.code === fetchSuccess) {
      const { edenred } = data.data;
      commit('setEdenredGroup', edenred);
      if (edenredBalanceTotal() > 0) {
        setEdenredBlockContent();
      }
    }
  };

const saveFetchEdenredResponse =
  (commit) =>
  ({ data }) => {
    const fetchFail = 0;
    if (data.code === fetchFail) {
      commit('setEdenredIsError', true);
      commit('setEdenredErrorTxt', data.description);
      return;
    }
    const fetchSuccess = 1;
    if (data.code === fetchSuccess) {
      const { balance } = data.data.edenred[0];
      const balanceConvertToNumber = parseInt(balance, 10);
      const thisEdenredRes = data.data.edenred[0];
      const mapEdenredGroup = getEdenredGroup().map((x) => ({ ...x }));
      mapEdenredGroup.push(thisEdenredRes);
      commit('setEdenredGroup', mapEdenredGroup);
      if (balanceConvertToNumber >= 0) {
        commit('setIsFetchEdenredHasBalance', true);
        commit('setThisEdenredBalance', balanceConvertToNumber);
      }
      if (edenredBalanceTotal() > 0) {
        setEdenredBlockContent();
      }
    }
  };

/** [取得 HamiPoint] API */
const getHamiPoint = ({ commit }) => fetchHamiPoint().then(saveHamiPoint(commit));

const queryEdenred = ({ commit }) => getBalance().then(saveQueryEdenredResponse(commit));

const fetchEdenred = ({ commit }, payload) => {
  const formData = new FormData();
  formData.append('serial[]', `SED${payload}`);
  // 手機版的宜睿是full screen modal, 故不用另外控制開關
  if (!isMobile()) {
    closeModal('EdenredModal');
  }
  commitMutation('Loading', 'updateLoading', true);
  fetchBalance(formData)
    .then(saveFetchEdenredResponse(commit))
    .finally(() => {
      commitMutation('Loading', 'updateLoading', false);
      if (!isMobile()) {
        openModal('EdenredModal');
      }
    });
};

/** 取得運費並寫入 store */
const getShip = ({ state: { cartType, isCycle, isVTP } }) => {
  getShipFare(cartType, isCycle, isVTP).then((res) => {
    if (res.data.code !== 1) {
      return;
    }
    commitMutation('CartDetail', 'shipFareDetail', res.data.data);
  });
};

/** 呼叫 cartDetail API */
const fetchCartDetail = ({ state, dispatch }) =>
  getCartDetail(state.cartType)
    .then((res) => saveCartDetail(res, dispatch))
    .catch((error) => {
      console.log(error);
      window.location.href = `${process.env.VUE_APP_ONLINE}`;
    });

const fetchShipFare = (dispatch) => () => dispatch('getShipFromStep1');

const getShipFromStep1 = ({ state: { amount, cartType, isVTP } }) => {
  const postData = {
    amount,
    type: cartType,
    isCycle: renderState('CardInfo', 'isCycle'),
    isVTP,
  };
  getShippingFee(postData)
    .then((res) => {
      const { shipFare, amounts } = res.data.data;
      const deliveryType = renderState('DeliveryInfo', 'delivery');
      // 寫入該車實際運費供計算
      commitMutation('CartDetail', 'shipFare', shipFare);
      // 篩選出 user 選的配送方式
      const response = (item) => item.delivery === deliveryType;
      const apiAmountData = amounts[0].filter(response);
      // 將 API 傳來的總計金額寫入 store
      commitMutation('CartDetail', 'summary', apiAmountData[0].amount);
      // 將 API 傳來的是否有運費寫入 store
      commitMutation('CartDetail', 'isShipFare', apiAmountData[0].isShipFare);
    })
    .catch((error) => {
      console.log(error);
      const redirectPath = isMobile() ? '/m/cart/choice' : '/cart/main';
      window.location.href = `${process.env.VUE_APP_ONLINE}${redirectPath}`;
    });
};
/** 是否顯示整車折抵訊息 */
const getIsDiscount = ({ isDiscount }) => isDiscount;

/** for 是否顯示運費的 component 用 from ship-tip.vue */
const getCartType = ({ cartType }) => cartType;

/** 傳給 computed 用 */
const getterCartDetail = ({ cartDetail }) => cartDetail;
const getterMobileCartMain = ({ mobileCartList }) => mobileCartList;
const getterCoupon = ({ coupons }) => coupons;
const getterCartPoint = ({ cartPoint }) => cartPoint;
const getterFormatPoint = ({ formatPoint }) => formatPoint;
const getterPointMoney = ({ pointMoney }) => pointMoney;
const getterDiscountSubTotal = ({ discountSubTotal }) => discountSubTotal;
const getterEdenredGroup = ({ edenredGroup }) => edenredGroup;

const defaultState = {
  cartDetail: [],
  /** 由 [取得購物車明細] API 取得 [購物車明細] */
  resStoreCartDetail: [],
  /** 陣列排列手機版 cartMain 頁 */
  mobileCartList: [],
  /** 購物商品數量 */
  cartCount: 0,
  /** user 選擇的車型 for Mobile 用
   * 購物車類型 1-一般配送, 2-限神腦門市, 3-限宅配, 4-電子票券, 5-預購
   * from cartChoice and cartMain
   * */
  cartType: '',
  /** 是否顯示 Line 提示 */
  isLinePoints: 0,
  /** 是否登入 */
  isLogin: false,
  /** cartMain 頁的商品明細列表 for mobile */
  cartList: [],
  /** 是否能整車折抵 */
  isDiscount: 0,
  /** 折扣名稱 */
  discountName: '',
  /** 由 [取得優惠券] API 取得 [優惠券明細] */
  coupons: [],
  /** 由 [getPoint] API 取得神腦幣完整資料 (物件) */
  cartPoint: {},
  /** 最終神腦幣 */
  point: 0,
  /** HamiPoint */
  hamiPoint: 0,
  /** 是否已授權 HamiPoint 取點數 */
  isAuthHamiPoint: false,
  /** 加入千分位格式的神腦幣 */
  formatPoint: 0,
  /** 計算過後的紅利金額 */
  pointMoney: 0,
  /** 是否勾選使用神腦幣 */
  isCheckPoint: false,
  /** 該車全部商品的小計總和 (含可折與不可折商品 ; 未扣紅利或折價券) */
  subTotal: 0,
  /** 該車可折商品小計 */
  discountSubTotal: 0,
  /** 由 [getShipFare] API 取得運費資訊 for cartMain 顯示用 */
  shipFareDetail: {},
  /** 計算過的購物車總計，for checkOut API 比對用 */
  amount: 0,
  /** user 選擇的折價券，for POST checkOut API 用 */
  selectCoupon: [],
  /** user 選擇的員購價，for POST checkOut API 用 */
  selectEmp: [],
  /** 總計 + 運費 from 第二次呼叫 [取得運費 + 應付金額] API 裡對應配送方式的 amount */
  summary: 0,
  /** 該車是否免運 for /step1 顯示用 */
  isShipFare: 0,
  /** 該車實際運費 for /step1 顯示用 */
  shipFare: 0,
  /** 是否為直接購買車(cart_type=5) ; Integer */
  isDirect: 0,
  /** 是否為電子票券車 */
  isVTP: 0,
  /** 神腦幣可折抵最大金額 / 整車最大可折抵金額 */
  pointMaxAmount: 0,
  /** 可用優惠券賣場 */
  couponMarts: [],
  edenredGroup: [],
  isFetchEdenredHasBalance: false,
  thisEdenredBalance: 0,
  edenredIsError: false,
  edenredErrorTxt: '',
  isCheckUsedEdenred: false,
  isShowChtLoginMsg: false,
  /** 0: 非R身份; 1: 老闆R; 2: 銷售R, 3: 個人R * */
  isRtype: 0,
  utm: '',
};

export default {
  namespaced: true,
  state: Object.assign({}, defaultState),
  mutations: {
    setCartDetail: makeMutation('cartDetail'),
    setResStoreCartDetail: makeMutation('resStoreCartDetail'),
    setMobileCartList: makeMutation('mobileCartList'),
    setCartCount: makeMutation('cartCount'),
    setCartList: makeMutation('cartList'),
    setCartType: makeMutation('cartType'),
    setIsLinePoints: makeMutation('isLinePoints'),
    setIsLogin: makeMutation('isLogin'),
    setIsDiscount: makeMutation('isDiscount'),
    setCoupons: makeMutation('coupons'),
    setProductQty,
    setCartPoint: makeMutation('cartPoint'),
    setPoint: makeMutation('point'),
    setHamiPoint: makeMutation('hamiPoint'),
    setIsAuthHamiPoint: makeMutation('isAuthHamiPoint'),
    setFormatPoint: makeMutation('formatPoint'),
    setPointMoney: makeMutation('pointMoney'),
    setIsCheckPoint: makeMutation('isCheckPoint'),
    setSubTotal: makeMutation('subTotal'),
    setDiscountSubTotal: makeMutation('discountSubTotal'),
    setShipFareDetail: makeMutation('shipFareDetail'),
    setAmount: makeMutation('amount'),
    setSelectCoupon: makeMutation('selectCoupon'),
    setSelectEmp: makeMutation('selectEmp'),
    setSummary: makeMutation('summary'),
    setIsShipFare: makeMutation('isShipFare'),
    setShipFare: makeMutation('shipFare'),
    setIsDirect: makeMutation('isDirect'),
    setIsVTP: makeMutation('isVTP'),
    setPointMaxAmount: makeMutation('pointMaxAmount'),
    setCouponMarts: makeMutation('couponMarts'),
    setEdenredGroup: makeMutation('edenredGroup'),
    setIsFetchEdenredHasBalance: makeMutation('isFetchEdenredHasBalance'),
    setThisEdenredBalance: makeMutation('thisEdenredBalance'),
    setEdenredIsError: makeMutation('edenredIsError'),
    setEdenredErrorTxt: makeMutation('edenredErrorTxt'),
    setIsCheckUsedEdenred: makeMutation('isCheckUsedEdenred'),
    setIsShowChtLoginMsg: makeMutation('isShowChtLoginMsg'),
    setIsRtype: makeMutation('isRtype'),
    setUtm: makeMutation('utm'),
    setCalculation,
    /** 重置 store */
    RESET_STATE(state) {
      Object.keys(state).forEach((prop) => {
        state[prop] = defaultState[prop];
      });
    },
  },
  getters: {
    getIsDiscount,
    getCartType,
    getterCartDetail,
    getterMobileCartMain,
    getterCoupon,
    getterCartPoint,
    getterFormatPoint,
    getterPointMoney,
    getterDiscountSubTotal,
    getterEdenredGroup,
  },
  actions: {
    fetchCartDetail,
    getMobileCartMain,
    getCouponList,
    getProductQty,
    getPoint,
    getHamiPoint,
    queryEdenred,
    fetchEdenred,
    getShip,
    fetchShipFare,
    getShipFromStep1,
  },
};
