<template>
  <div class="header-navbar" v-bind:class="[this.fixedHeader ? 'fixed' : '']">
    <div class="container">
      <div class="hc-left">
        <a v-if="fixedHeader" :href="baseUrl" class="btn-home">
          <i class="icon icon-home"></i>
          回首頁
        </a>
        <a :href="baseUrl" title="神腦生活購物" class="site-logo">
          <img src="/images/home/logo-and-doll-page.png" alt="神腦生活LOGO" width="385" height="130" />
        </a>
        <a class="adbox-sm-rectangle" href="javascript:void(0);" title="活動標題">
          <img src="/images/home/topbanner_sm_rectangle_18q1-2.png" alt="活動標題" />
        </a>
      </div>
      <div class="hc-right">
        <!-- 帳戶 -->
        <User />
        <!-- 小車 -->
        <Cart />
      </div>
      <!-- <div class="hc-middle"></div> -->
    </div>
  </div>
</template>

<script>
import Cart from '@/components/desktop/Header/Elements/cart.vue';
import User from '@/components/desktop/Header/Elements/user.vue';

const data = () => ({
  fixedHeader: false,
  baseUrl: process.env.VUE_APP_ONLINE,
});

export default {
  name: 'nav-bar',
  data,
  methods: {
    handleScroll() {
      if (this.fixedHeader) {
        this.fixedHeader = window.scrollY !== 0;
        return;
      }
      this.fixedHeader = window.scrollY > 112;
    },
  },
  computed: {
    headerFixed() {
      /** @const {number} headerScrollHeight header高度 */
      const headerTopBar = document.querySelector('.header-topbar');
      const headerScrollHeight = headerTopBar !== null ? headerTopBar.offsetHeight : 0;
      return window.scrollY > headerScrollHeight;
    },
  },
  components: {
    User,
    Cart,
  },
  created() {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
};
</script>
<style scoped>
.btn-home {
  padding-top: 12px;
}
</style>
