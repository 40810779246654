// import '@babel/polyfill';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'formdata-polyfill';
import Vue from 'vue';
import store from '@/store/index';
import { setAxiosCORS } from '@/helpers/axios';
import { router } from '@/pages/default/router';
import App from '@/pages/default/app.vue';
/** 設定 Axios 使用 CORS 方式 */
setAxiosCORS();

Vue.config.productionTip = false;

if (['stg', 'lab', 'development'].indexOf(process.env.VUE_APP_SENAO_ENV) > -1) {
  Vue.config.debug = true;
  Vue.config.devtools = true;
}

// global $filters
Vue.prototype.$filters = {
  /** dollar @param {Number} val */
  dollar: (val) => `$${val.toLocaleString()}`,
  /** 百分比 @param {Number} val */
  percent: (val) => (val === 0 ? `${val}` : `${val}%`),
};

new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount('#app');
