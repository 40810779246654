/**
 * 切換頁面種類
 * form 預設表單頁 default-view
 * bank 銀行分期列表頁 bank-installment-list
 * uniform 發票統編記事本列表頁
 * */
const pages = ['form', 'bank', 'uniform', 'invoice'];

const state = {
  page: 'form',
  pageData: [],
  // 統編選取內容
  uniformIndex: null,
  // 是否可更新 city town
  canUpdateCityTown: false,
  isPayerReadOnly: false,
  isSelectUniform: false,
};

const mutations = {
  /** 切換頁面 */
  changePage(
    state,
    data = {
      page: 'form',
      data: [],
    }
  ) {
    if (pages.indexOf(data.page) >= 0) {
      state.page = data.page;
      state.pageData = data.data;
    }
  },
  setUniformIndex(state, data) {
    state.uniformIndex = data;
  },
  updateCityTownStatus(state, data) {
    state.canUpdateCityTown = data;
  },
  setRtype(state, data) {
    state.isPayerReadOnly = data;
  },
  setSelectUniform(state, data) {
    state.isSelectUniform = data;
  },
};

const actions = {
  /** 切換頁面 */
  changePage(
    context,
    data = {
      page: 'form',
      data: [],
    }
  ) {
    context.commit('changePage', data);
  },
  setUniformIndex(context, data) {
    context.commit('setUniformIndex', data);
  },
  setSelectUniform(context, data) {
    context.commit('setSelectUniform', data);
  },
  updateCityTownStatus(context, data) {
    context.commit('updateCityTownStatus', data);
  },
};

const getters = {
  /** 顯示銀行分期列表 */
  getPage(state) {
    return state.page;
  },
  /** 取得該頁面所需資料 (銀行分期列表 or 統編記事本列表) */
  getpageData() {
    return state.pageData || [];
  },
  /** 取得統編選取內容 */
  getUniform() {
    return state.uniform;
  },
  getUniformIndex() {
    return state.uniformIndex;
  },
  getUpdateCityTownStatus() {
    return state.canUpdateCityTown;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
