const convertToString = (value) => (['string', 'number'].includes(typeof value) ? String(value) : '');

const mask = (maskText, maskPosition) => {
  const maskedText = convertToString(maskText);
  return maskPosition >= maskedText.length
    ? maskedText
    : maskedText.substring(0, maskPosition) + '*'.repeat(maskedText.length - maskPosition);
};

/** 僅顯示第一個字 */
export const maskName = (name) => mask(convertToString(name), 1);

/** 02-29281234-235 遮罩成02-29281***-235  */
export const maskTel = (tel) => {
  const maskedText = convertToString(tel);
  return [0, 1, 2, 3, 4].includes(maskedText.indexOf('-'))
    ? maskedText.replace(/^([0-9]*[\\-][0-9]*)[0-9]{3}/, '$1***')
    : maskedText.replace(/^([0-9]*)[0-9]{3}/, '$1***');
};

/** 29281234 遮罩成 29281*** */
export const maskPhone = (tel) => convertToString(tel).replace(/.{3}$/, '***');

/** 只留前四碼 */
export const maskEmail = (email) => mask(convertToString(email), 4);

/** 數字部分遮罩 */
export const maskAddress = (address) => convertToString(address).replace(/[0-9]/g, '*');

/** 遮罩第5、7、9碼 */
export const maskMobile = (mobile) => convertToString(mobile).replace(/^(.{4}).(.).(.)./, '$1*$2*$3*');

/** 遮罩西元年末兩碼 及 日 */
export const maskBirthday = (val) =>
  convertToString(val)
    .replace(/^(.{2}).{2}/, '$1**')
    .replace(/[0-9]{2}$/, '**')
    .replace(/[0-9]{1}$/, '*');
