import { makeMutation } from '@/helpers/vuex';
import fetchUniforms from '@/api/step1/uniforms';
import { addProperty, makeToggleTitle } from '@/helpers/modal';

/** 從 [未選取] 變成 [選取] */
const toggleTitle = makeToggleTitle('invoices');

/** 儲存 [統編收件人] */
const saveInvoices = (commit) => (data) => commit('setInvoices', data);

/** [統編記事本] API */
const getInvoices = ({ commit }) =>
  fetchUniforms()
    .then(addProperty('caption', ['data', 'data']))
    .then(saveInvoices(commit));

export default {
  namespaced: true,
  state: {
    /** 從 API 下載的 [統編記事本] */
    invoices: [],
    /** 所選擇 [發票收件人] */
    selectedItem: {},
    /** 關閉 [統編記事本] */
    dataDismiss: '',
    /** 是否有打過 uniform API */
    hasLoadInvoice: false,
  },
  mutations: {
    setInvoices: makeMutation('invoices'),
    setSelectedItem: makeMutation('selectedItem'),
    setDataDismiss: makeMutation('dataDismiss'),
    toggleTitle,
    setHasLoadInvoice: makeMutation('hasLoadInvoice'),
  },
  actions: {
    getInvoices,
  },
};
