import { commitMutation, renderGetter, renderState } from '@/helpers/vuex';
import { getCartCount } from '@/api/get-cart-count';
import { isFromWhere } from '@/helpers/is-from-app';

const methods = {
  getCart() {
    getCartCount().then(this.getCartCountResponse).then(this.getCartType).catch(this.catchResponse);
  },
  getFinishCart() {
    getCartCount().then(this.getCartCountResponse).catch(this.catchResponse);
  },
  getCartCountResponse(res) {
    if (res === undefined) {
      return false;
    }
    if ((res.status !== 200 && res.data.length <= 0) || res.data.code !== 1 || res.data.data.length <= 0) {
      return false;
    }
    const { isLogin, memberName, carts, isFromApp } = res.data.data;
    // 寫入呼叫過 getCartCount API
    commitMutation('Login', 'call', true);
    // 如已登入
    if (isLogin === true) {
      commitMutation('Login', 'user', memberName);
      commitMutation('Login', 'isLogin', true);
      this.isLogin = true; // for PC_cart-main.vue [data] ; 是否要呼叫收藏 API 用
    }
    // 篩選出車裡有商品
    const hasItem = (item) => item.count > 0;
    const cartList = {
      info: carts ? carts.filter(hasItem) : [],
      total: carts.filter(hasItem).reduce((sum, x) => sum + x.count, 0),
    };
    commitMutation('Cart', 'cartInfo', cartList.info);
    commitMutation('Cart', 'cartTotal', cartList.total);
    // 寫入來源
    commitMutation('Cart', 'isFromApp', isFromApp);
    isFromWhere(isLogin);
    return res.data.data;
  },
  getCartType(res) {
    const url = new URL(window.location.href);
    const toCartType = url.searchParams.get('type');
    const isDirect = toCartType === '5';
    const isFromApp = renderState('Cart', 'isFromApp');
    // 車裡有商品且為有帶 type 的 URL
    if (res.carts.length > 0) {
      const findCart = res.carts.find((item) => item.cartType === toCartType);
      // 載入時網址自動轉址進入預設 ?type= 車裡非 cart/main
      // 非預購車且找不到該車型 (ex: user key 非法 type at URL)
      if (!this.isMobile && !isDirect && findCart === undefined) {
        // 自動導向有商品的第一組 cartType
        return this.$router.replace({ path: '/cart/main', query: { type: res.carts[0].cartType } });
      }
      // At PC 有找到該車型，即寫入至 this.getUrlCartType 供 props 子組件使用 ; Mobile 即為 null
      return (this.getUrlCartType = toCartType);
    }
    // 車裡無商品且是預購車
    if (isDirect) {
      return (this.getUrlCartType = toCartType);
    }
    // 未登入時 || at cartChoice 頁且 res.carts.length = 0 時則跳過
    return false;
  },
  catchResponse(error) {
    console.log(error);
    window.location.href = process.env.VUE_APP_ONLINE;
    return error;
  },
  checkPlusPayQrCode() {
    const hasQrCode = renderState('OrderFinishInfo', 'plusPayQrCode');
    const hasPollingUrl = renderState('OrderFinishInfo', 'plusPayPollingUrl');
    if (!hasQrCode || !hasPollingUrl) {
      this.$router.push({ name: 'cartMain' });
    }
  },
};

const data = () => ({
  getUrlCartType: null,
  isLogin: false,
});

export default {
  data,
  computed: {
    // 購物車裡的總數量
    isEmpty() {
      return renderGetter('Cart', 'getInfoTotal') === 0 && this.getUrlCartType !== '5';
    },
  },
  methods,
  watch: {
    $route(to) {
      switch (to.path.toLocaleLowerCase()) {
        case '/cart/finished':
          // router 針對 finish 頁呼叫 getCartCount 更新小車
          this.getFinishCart();
          break;
        case '/cart/step1':
          break;
        case '/cart/pluspay':
          this.checkPlusPayQrCode();
          break;
        default:
          this.getCart();
      }
    },
  },
};
