<template>
  <footer>
    <div class="container">
      <div class="row footer-nav">
        <div class="col-md-4 footer-nav-item">
          <h5 class="title">關於神腦生活</h5>
          <ul class="nav">
            <li>
              <a href="https://www.senao.com.tw/" target="_blank"> 神腦官網 </a>
            </li>
            <li>
              <a href="https://www.youtube.com/@TheSENAOORG" target="_blank">神腦基金會</a>
            </li>
            <li>
              <a :href="`${baseUrl}/About`" target="_blank"> 關於我們 </a>
            </li>
            <li>
              <a :href="`${baseUrl}/About/terms`" target="_blank"> 會員服務條款 </a>
            </li>
            <li>
              <a :href="`${baseUrl}/About/privacy`" target="_blank"> 隱私權政策 </a>
            </li>
            <li>
              <a :href="`${baseUrl}/About/sitemap`" target="_blank"> 網站導覽 </a>
            </li>
          </ul>
        </div>
        <div class="col-md-4 footer-nav-item">
          <h5 class="title">服務 &amp; 合作</h5>
          <ul class="nav">
            <li>
              <a :href="`${baseUrl}/Location`" target="_blank"> 服務據點 </a>
            </li>
            <li>
              <a :href="`${baseUrl}/ActivityArea`" target="_blank"> 活動專區 </a>
            </li>
            <li>
              <a href="https://online.senao.com.tw/birthday/" target="_blank"> 會員生日禮 </a>
            </li>
            <li>
              <a :href="`${baseUrl}/Order/list`" target="_blank"> 訂單查詢 </a>
            </li>
            <li>
              <a :href="`${baseUrl}/supplier`" target="_blank"> 合作提案 </a>
            </li>
          </ul>
        </div>
        <div class="col-md-4 footer-nav-item">
          <h5 class="title">客服中心</h5>
          <ul class="nav">
            <li>
              <a :href="`${baseUrl}/Supplement/returnPolicy`" target="_blank"> 退貨須知 </a>
            </li>
            <li>
              <a :href="`${baseUrl}/FAQ`" target="_blank"> 客服FAQ </a>
            </li>
            <li>
              <a :href="`${baseUrl}/Supplement/vendorRepair`" target="_blank"> 原廠維修 </a>
            </li>
          </ul>
        </div>
        <div class="col-md-12 footer-nav-item">
          <ul class="footer-feature-list">
            <li>
              <a :href="`${baseUrl}/Supplement/delivery#pay`" target="_blank">
                <i class="icon icon-footer-free"></i>
                <div class="has-inline">
                  <h5 class="title">滿額免運費</h5>
                  <p class="content">消費滿490元免運費</p>
                </div>
              </a>
            </li>
            <li>
              <a href="https://lin.ee/KY7FkVX" target="_blank">
                <i class="icon icon-footer-line"></i>
                <div class="has-inline">
                  <h5 class="title">神腦生活LINE</h5>
                  <p class="content">加入神腦生活LINE@</p>
                </div>
              </a>
            </li>
            <li>
              <a :href="`${baseUrl}/Supplement/purview`" target="_blank">
                <i class="icon icon-footer-good"></i>
                <div class="has-inline">
                  <h5 class="title">神腦會員福利</h5>
                  <p class="content">會員獨享優惠</p>
                </div>
              </a>
            </li>
            <li>
              <a href="https://www.facebook.com/senaoonline" target="_blank">
                <i class="icon icon-footer-share"></i>
                <div class="has-inline">
                  <h5 class="title">神腦國際粉絲團</h5>
                  <p class="content">加入FB粉絲團</p>
                </div>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="row footer-info">
        <div class="col-md-16">
          <p class="copyright">
            神腦國際企業股份有限公司&nbsp;&nbsp;地址：台灣23148新北市新店區中正路531號2樓
            <br />
            Copyright@2016 SENAO INTERNATIONAL CO.,LTD
          </p>
        </div>
        <div class="col-md-8">
          <div class="app-downloads">
            <span class="text-muted">
              神腦生活APP下載
              <br />
              <a
                class="underline inverted"
                href="https://helpcenter.senao.com.tw/event/20170101_app_renew"
                target="_blank">
                詳細說明
              </a>
            </span>
            <img class="qrcode" alt="AppStore" src="/images/qrcode-header-download.png" />
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
const url = process.env.VUE_APP_ONLINE;

export default {
  name: 'footerComponent',
  data() {
    return {
      baseUrl: url,
    };
  },
};
</script>
