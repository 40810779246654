import axios from 'axios';

const API_GET_SHIP_FARE = `${process.env.VUE_APP_FRONT_API}${process.env.VUE_APP_FRONT_SHIP_FARE}`;
const API_GET_DETAIL = `${process.env.VUE_APP_FRONT_API}${process.env.VUE_APP_API_GET_DETAIL}`;
const API_GET_COUPONS = `${process.env.VUE_APP_FRONT_API}${process.env.VUE_APP_API_GET_COUPONS}`;
const API_GET_GET_BONUS = `${process.env.VUE_APP_FRONT_API}${process.env.VUE_APP_API_GET_BONUS}`;
const API_GET_CART_DISCOUNT = `${process.env.VUE_APP_FRONT_API}${process.env.VUE_APP_API_GET_CART_DISCOUNT}`;
const API_DELETE_PRODUCT = `${process.env.VUE_APP_FRONT_API}${process.env.VUE_APP_API_DELETE_PRODUCT}`;
const API_DELETE_ADDITIONAL = `${process.env.VUE_APP_FRONT_API}${process.env.VUE_APP_API_DELETE_ADDITIONAL}`;
const API_COLLECT_PRODUCT = `${process.env.VUE_APP_FRONT_API}${process.env.VUE_APP_API_COLLECT_PRODUCT}`;
const API_CART_MAIN_CHECKOUT = `${process.env.VUE_APP_FRONT_API}${process.env.VUE_APP_API_CART_MAIN_CHECKOUT}`;
const API_UPDATE_PRODUCT_QTY = `${process.env.VUE_APP_FRONT_API}${process.env.VUE_APP_API_UPDATE_PRODUCT_QTY}`;
const API_GET_BALANCE = `${process.env.VUE_APP_FRONT_API}${process.env.VUE_APP_API_GET_EDENRED_GETSERIALS}`;
const API_FETCH_BALANCE = `${process.env.VUE_APP_FRONT_API}${process.env.VUE_APP_API_FETCH_EDENRED_GETBALANCE}`;
const API_DELETE_BALANCE = `${process.env.VUE_APP_FRONT_API}${process.env.VUE_APP_API_DELETE_EDENRED_GETBALANCE}`;

export const getShipFare = (type, isCycle = 0, isVTP = 0) =>
  axios.get(API_GET_SHIP_FARE, { params: { amounts: 0, type, isCycle, isVTP } });
export const getCartDetail = (type) => axios.get(API_GET_DETAIL, { params: { type } });
export const getCoupons = (formData) => axios.post(API_GET_COUPONS, formData);
export const getBonus = (amount) => axios.get(API_GET_GET_BONUS, { params: { amount } });
export const getBalance = () => axios.get(API_GET_BALANCE);
export const getCartDiscount = (formData) => axios.post(API_GET_CART_DISCOUNT, formData);

/** 購物車異動相關動作 */
const cartChangePost = (url, data) => {
  // xsrf token flag
  data.append('enabledXsrf', true);

  // axios config
  const config = {
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    url,
    data,
  };
  return axios(config);
};

export const deleteProduct = (formData) => cartChangePost(API_DELETE_PRODUCT, formData);
export const deleteAdditional = (formData) => cartChangePost(API_DELETE_ADDITIONAL, formData);
export const updateProductQty = (formData) => cartChangePost(API_UPDATE_PRODUCT_QTY, formData);
export const collectProduct = (formData) => cartChangePost(API_COLLECT_PRODUCT, formData);
export const cartMainCheckout = (formData) => cartChangePost(API_CART_MAIN_CHECKOUT, formData);
export const removeEdenred = (formData) => cartChangePost(API_DELETE_BALANCE, formData);
export const fetchBalance = (formData) => cartChangePost(API_FETCH_BALANCE, formData);
