import { makeMutation } from '@/helpers/vuex';

const defaultState = {
  /** 從 API 來的第一組發票開立代號 */
  invoiceDefault: '',
  /** 從 API 來的可開立的發票代號 */
  invoiceType: [],
  /** 預設發票開立方式 */
  /** 01: 電子發票-個人, 04: 發票捐贈, 05: 電子發票-公司(紙本) */
  invoice: '01',
  /** for PC 電子發票載具 */
  /** 01: 神腦會員載具, 02: 手機條碼載具, 03: 自然人憑證條碼載具 */
  electronicInvoice: '01',
  /** 發票捐贈團體列表 */
  donateInvoiceList: [],
  /** 發票捐贈團體 */
  /** 050: 財團法人老五老基金會 */
  /** 2828: 財團法人天主教伯利恆文教基金會 */
  /** 111111: 財團法人臺中市私立惠明盲校 */
  donateInvoice: '',
  /** 手機條碼載具 */
  phoneDeviceCode: '',
  /** 自然人憑證條碼載具 */
  idDeviceCode: '',
  /** 公司統編 */
  companyNo: '',
  /** 公司抬頭 */
  companyTitle: '',
  /** 發票收件人 */
  recipient: '',
  /** 郵遞區號 */
  addressZip: '',
  /** 發票寄送地址市 ID */
  addressCityId: '',
  /** 收件地址之市 */
  addressCity: '',
  /** 發票寄送地址鄉鎮區 ID */
  addressTownId: '',
  /** 收件地址之鄉鎮區 */
  addressTown: '',
  /** 發票寄送地址 */
  address: '',
  /** 設定為常用統編記事本 */
  alwaysUniform: false,
  cityId: '',
  townId: '',
  isSaveDeviceCode: false,
};

export default {
  namespaced: true,
  state: Object.assign({}, defaultState),
  mutations: {
    setInvoiceDefault: makeMutation('invoiceDefault'),
    setInvoiceType: makeMutation('invoiceType'),
    setInvoice: makeMutation('invoice'),
    setElectronicInvoice: makeMutation('electronicInvoice'),
    setInvoiceMethod: makeMutation('invoiceMethod'),
    setDonateInvoiceList: makeMutation('donateInvoiceList'),
    setCompanyNo: makeMutation('companyNo'),
    setCompanyTitle: makeMutation('companyTitle'),
    setRecipient: makeMutation('recipient'),
    setAddressZip: makeMutation('addressZip'),
    setAddressCityId: makeMutation('addressCityId'),
    setAddressCity: makeMutation('addressCity'),
    setAddressTownId: makeMutation('addressTownId'),
    setAddressTown: makeMutation('addressTown'),
    setAddress: makeMutation('address'),
    setDonateInvoice: makeMutation('donateInvoice'),
    setPhoneDeviceCode: makeMutation('phoneDeviceCode'),
    setIdDeviceCode: makeMutation('idDeviceCode'),
    setAlwaysUniform: makeMutation('alwaysUniform'),
    setCityId: makeMutation('cityId'),
    setTownId: makeMutation('townId'),
    setIsSaveDeviceCode: makeMutation('isSaveDeviceCode'),
    /** 重置 store */
    RESET_STATE(state) {
      Object.keys(state).forEach((prop) => {
        state[prop] = defaultState[prop];
      });
    },
  },
};
