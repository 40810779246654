/**
 * marquee status
 */

const state = {
  /** 列表 */
  list: [],
  /** 呼叫過 api? */
  isCall: false,
};

const mutations = {
  updateMarqueeStatus(state, payload) {
    state.list = payload || [];
    state.isCall = true;
  },
};

const actions = {
  updateMarquee(context, payload) {
    context.commit('updateMarqueeStatus', payload);
  },
};

const getters = {
  getList(state) {
    return state.list;
  },
  checkCall(state) {
    return state.isCall;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
