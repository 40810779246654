<template>
  <!-- ### 通用連結 -->
  <div class="header-topbar">
    <div class="container">
      <ul class="nav nav-inline pull-left">
        <li class="popover-hover header-hover">
          <div class="popover-nav">
            <a href="https://helpcenter.senao.com.tw/event/20170101_app_renew/" target="_blank">下載App</a>
          </div>
          <div class="popover bottom">
            <div class="popover-arrow"></div>
            <div class="nav nav-list">
              <img src="/images/qrcode-header-download.png" alt="神腦生活App Qrcode" />
              <p>神腦生活App</p>
            </div>
          </div>
        </li>
        <li><a :href="locationUrl" target="_blank">服務據點</a></li>
        <li>
          <a href="https://www.senyoung.com.tw/?utm_source=senao_ec&utm_medium=header" target="_blank">神揚保代</a>
        </li>
      </ul>
      <ul class="nav nav-inline pull-right">
        <li>
          <a class="share-scare" :href="articleUrl" target="_blank"><i class="icon icon-heart-scare"></i> S-CARE</a>
        </li>
        <li class="popover-hover header-hover">
          <div class="popover-nav">
            <a class="share-line" href="https://lin.ee/KY7FkVX" target="_blank"
              ><i class="icon icon-share-line"></i> 加入LINE</a
            >
          </div>
          <div class="popover bottom header-line">
            <div class="popover-arrow"></div>
            <div class="nav nav-list">
              <img src="/images/qrcode-header-line.png" alt="神腦生活LINE@ Qrcode" />
              <p>神腦生活LINE@</p>
            </div>
          </div>
        </li>
        <li>
          <a class="share-youtube" href="https://www.youtube.com/@SenaoTaiwan" target="_blank"
            ><i class="icon icon-share-youtube"></i> YouTube</a
          >
        </li>
        <li>
          <a class="share-fb" href="https://www.facebook.com/senao.taiwan/" target="_blank"
            ><i class="icon icon-share-fb"></i> FB粉絲團</a
          >
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
const url = process.env.VUE_APP_ONLINE;

export default {
  name: 'top-bar',
  data() {
    return {
      baseUrl: url,
      locationUrl: `${url}/Location`,
      articleUrl: `${url}/Article`,
      sCareUrl: 'https://bit.ly/2JiKQ8V',
    };
  },
};
</script>
