import { prop } from 'ramda';
import { makeMutation } from '@/helpers/vuex';

/** [結帳] / [授權處理] Enum for PC */
export const step1ComponentEnum = {
  step1: 'Checkout',
  security: 'Security',
};

export default {
  namespaced: true,
  state: {
    /** [結帳] 或 [授權處理] for PC */
    dynamicComponent: prop('step1', step1ComponentEnum),
  },
  mutations: {
    setDynamicComponent: makeMutation('dynamicComponent'),
  },
};
